import React from "react";
import Text from "components/text";
import styles from "./alert.module.css";
import draftIcon from "assets/images/draft-alert-icon.svg";
import pendingIcon from "assets/images/pending-alert-icon.svg";
import overdueIcon from "assets/images/overdue-alert-icon.svg";
import completedIcon from "assets/images/completed-alert-icon.svg";
const GrowingAlert = ({ number = "", type = "draft", text = "", page }) => {
  const alertIcons = {
    draft: draftIcon,
    pending: pendingIcon,
    overdue: overdueIcon,
    completed: completedIcon,
    activated: completedIcon,
  };
  return (
    <div
      className={`d-flex 
      ${page === "dashboard" ? styles.containerDashboard : styles.container}
      ${text ? styles.noGrow : ""} 
      align-items-center `}
    >
      <img
        src={alertIcons[type.toLowerCase()]}
        alt="alert-icon"
        className={`${styles.icon}`}
      />
      <Text className={`${styles[type.toLowerCase()]}`}>
        {number + " " + (text || type)}
      </Text>
    </div>
  );
};

export default GrowingAlert;
