import { Network } from "../Network";
import { ReportsEndPoints } from "apis/EndPoints/ReportsEndPoints";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class ReportServices {
  static getReportDetails(data = {}) {
    return Network.fetch(
      ReportsEndPoints.getReportDetails.url(
        data?.company,
        data?.project,
        data?.firstColumnName,
        data?.secondColumnName,
        data?.minValue,
        data?.maxValue,
        data?.sortBy,
        data?.status,
        data?.filteredColumns,
        data?.filterCombination,
        data?.subcontractorFilter,
        data?.reportRequestName,
        data?.responseFilter
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.getReportDetails.method,
        body: JSON.stringify(data),
      }
    );
  }
  static getReportAccessLevel(data = {}) {
    return Network.fetch(
      ReportsEndPoints.getReportAccessLevel.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.getReportAccessLevel.method,
      }
    );
  }

  static modifyIssueDateReport(data = {}) {
    return Network.fetch(
      ReportsEndPoints.modifyIssueDateReport.url(data?.company, data?.project),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ReportsEndPoints.modifyIssueDateReport.method,
        body: JSON.stringify(data?.editedData),
      }
    );
  }
}
