import { Network } from "../Network";
import { ProjectsEndPoints } from "apis/EndPoints/ProjectsEndPoints";
import { useHandleQueryParams } from "hooks/useHandleQueryParam";

export class ProjectServices {
  static getProjects(data = {}) {
    return Network.fetch(ProjectsEndPoints.getProjects.url(data?.company_id), {
      headers: {
        "Content-type": "application/json",
        Authorization: data?.code,
        From: data?.loginEmail,
      },
      method: ProjectsEndPoints.getProjects.method,
      // body: JSON.stringify(data),
    });
  }

  static getProjectsDetails(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getProjectDetails.url(data?.company, data.project),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ProjectsEndPoints.getProjectDetails.method,
        // body: JSON.stringify(data),
      }
    );
  }

  static getExportData(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getExportData.url(data?.company, data.project),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ProjectsEndPoints.getExportData.method,
        body: JSON.stringify(data),
      }
    );
  }

  static setLastUsedView(data = {}) {
    return Network.fetch(ProjectsEndPoints.setLastUsedView.url(data?.company), {
      headers: {
        "Content-type": "application/json",
        Authorization: data?.code,
        From: data?.loginEmail,
      },
      method: ProjectsEndPoints.setLastUsedView.method,
      body: JSON.stringify(data),
    });
  }

  static getProjectsDetailsFromSubmittals(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getProjectDetailsFromSubmittals.url(
        data?.company,
        data?.project
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ProjectsEndPoints.getProjectDetailsFromSubmittals.method,
        // body: JSON.stringify(data),
      }
    );
  }

  static getProjectsContactPage(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getProjectsContactPage.url(data?.company_id, data.id),
      {
        method: ProjectsEndPoints.getProjectDetails.method,
        body: JSON.stringify(data),
      }
    );
  }

  static getProjectsInfo(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getProjectsInfo.url(data?.company_id, data?.project_id),
      {
        method: ProjectsEndPoints.getProjectsInfo.method,
        body: JSON.stringify(data),
      }
    );
  }
  static getItemsPendingApprovalsHistory(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getItemsPendingApprovalsHistory.url(
        data?.company_id,
        data?.project_id
      ),
      {
        method: ProjectsEndPoints.getItemsPendingApprovalsHistory.method,
        headers: {
          Authorization: `Bearer ${data.code}`,
          From: `${data.email}`,
        },
      }
    );
  }

  static procurementLogPostSubmittalToProcore(data = [], authData = {}) {
    return Network.fetch(
      ProjectsEndPoints.postSubmittaltoProcore.url(
        authData?.company,
        authData?.project
      ),
      {
        method: ProjectsEndPoints.postSubmittaltoProcore.method,
        headers: {
          Authorization: authData?.code,
          From: authData?.loginEmail,
        },
        body: JSON.stringify(data),
      }
    );
  }
  static getItemsPendingApprovals(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.getItemsPendingApprovals.url(
        data?.company_id,
        data?.project_id
      ),
      {
        method: ProjectsEndPoints.getItemsPendingApprovals.method,
        headers: {
          Authorization: `Bearer ${data.code}`,
          From: `${data.email}`,
        },
      }
    );
  }
  static sendItemsPendingApprovals(data = {}) {
    return Network.fetch(
      ProjectsEndPoints.sendItemsPendingApprovals.url(
        data?.company_id,
        data?.project_id
      ),
      {
        method: ProjectsEndPoints.sendItemsPendingApprovals.method,
        headers: {
          Authorization: `Bearer ${data.code}`,
          From: `${data.email}`,
        },
        body: JSON.stringify(data.values),
      }
    );
  }

  static addRow(data = [], authData = {}) {
    return Network.fetch(
      ProjectsEndPoints.saveRow.url(authData?.company_id, authData?.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: authData?.code,
          From: authData?.loginEmail,
        },
        method: ProjectsEndPoints.saveRow.method,
        body: JSON.stringify(data),
      }
    );
  }

  static EditRow(data = [], authData = {}) {
    return Network.fetch(
      ProjectsEndPoints.EditRow.url(authData?.company_id, authData?.project_id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: authData?.code,
          From: authData?.loginEmail,
        },
        method: ProjectsEndPoints.EditRow.method,
        body: JSON.stringify(data),
      }
    );
  }

  static deleteRow(data = {}, authData = {}) {
    return Network.fetch(
      ProjectsEndPoints.deleteRow.url(
        authData?.company_id,
        authData?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: authData?.code,
          From: authData?.loginEmail,
        },
        method: ProjectsEndPoints.deleteRow.method,
        body: JSON.stringify(data),
      }
    );
  }

  // static saveUploadedSchedule(data = {}) {
  //   console.log(data, "data");
  //   return Network.fetch(
  //     ProjectsEndPoints.UploadedSchedule.url(
  //       data?.company_id,
  //       data?.project_id
  //     ),
  //     {
  //       method: ProjectsEndPoints.UploadedSchedule.method,
  //       body: JSON.stringify(data),
  //     }
  //   );
  // }

  static saveUploadedSchedule(data = {}) {
    console.log(data, "data");
    return Network.fetch(
      ProjectsEndPoints.UploadedSchedule.url(
        data?.company_id,
        data?.project_id
      ),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ProjectsEndPoints.UploadedSchedule.method,
        body: JSON.stringify(data),
      }
    );
  }
  static saveProcurementLogSchedule(data, company_id, id) {
    console.log(data, "data");
    return Network.fetch(
      ProjectsEndPoints.UploadedProcurementLogSchedule.url(company_id, id),
      {
        headers: {
          "Content-type": "application/json",
          Authorization: data?.code,
          From: data?.loginEmail,
        },
        method: ProjectsEndPoints.UploadedProcurementLogSchedule.method,
        body: JSON.stringify(data),
      }
    );
  }
  static procurementLogAdminPage(data, company_id, id) {
    return Network.fetch(ProjectsEndPoints.AdminPage.url(company_id, id), {
      headers: {
        "Content-type": "application/json",
        Authorization: data?.code,
        From: data?.loginEmail,
      },
      method: ProjectsEndPoints.AdminPage.method,
    });
  }
  static NonWorkingDays(data, company_id, id) {
    return Network.fetch(
      ProjectsEndPoints.insertEditDeleteNonWorkingDays.url(company_id, id),
      {
        method: ProjectsEndPoints.insertEditDeleteNonWorkingDays.method,
        body: JSON.stringify(data),
      }
    );
  }
  static WorkingDays(data, company_id, id) {
    return Network.fetch(
      ProjectsEndPoints.updateWorkingDays.url(company_id, id),
      {
        method: ProjectsEndPoints.updateWorkingDays.method,
        body: JSON.stringify(data),
      }
    );
  }
}
