import {
  Dropdown,
  Button,
  Tooltip,
  Radio,
  Select,
  Input,
  Table,
  Modal,
  Menu,
  Switch,
} from "antd";
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import LoadingComponent from "components/loading-component";
import Text from "components/text";
import CompanyAdmin from "pages/company-admin";
import { CompanyAdminServices } from "apis/Services/CompanyAdminService";
import styles from "./company-dashboard.module.css";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { HolderOutlined, InfoCircleFilled } from "@ant-design/icons";

const RowContext = React.createContext({});

const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{ cursor: "move" }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const SortableRow = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });

  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
  };

  const contextValue = useMemo(
    () => ({ setActivatorNodeRef, listeners }),
    [setActivatorNodeRef, listeners]
  );

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

export default function CreateView({
  selectedViewName,
  projectsDropDown,
  viewName,
  setViewName,
  viewDescription,
  setViewDescription,
  setCreateFlag,
  readCompanySchedule,
  editFlag,
  setEditFlag,
}) {
  let code = localStorage.getItem("token");
  let loginEmail = localStorage.getItem("email");
  let Auth = {
    code,
    loginEmail,
  };

  const { company_id } = useParams();

  const [isLoading, setIsLoading] = useState();
  const [dataSample, setDataSample] = useState([]);
  const [columnsInfo, setColumnsInfo] = useState(selectedViewName.columnsInfo);
  const [originalColumnsInfo, setOriginalColumnsInfo] = useState(
    selectedViewName.columnsInfo
  );
  const [initialSetupDone, setInitialSetupDone] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);
  const [viewsProjects, setViewsProjects] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [changesMadeFlag, setChangesMadeFlag] = useState(false);
  const [oldViewName, setOldViewName] = useState("");

  const [showAllColumns, setShowAllColumns] = useState(true);

  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      viewName: viewName,
      viewDescription: viewDescription,
      assignedProjects:
        selectedViewName?.viewProjects?.map(
          (project) => project.split("-")[0]
        ) || assignedProjects,
    },
  });

  const getSampleProcurementLogsViews = (value) => {
    setIsLoading(true);
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      projectID: value,
      viewName: selectedViewName.viewName,
    };
    CompanyAdminServices.getSampleProcurementLogsViews(data)
      .then((res) => {
        setDataSample(res.dataSample);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createProcurementLogsViews = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      viewName: viewName,
      viewDescription: viewDescription,
      columnsInfo: [columnsInfo],
      assignedProjects: assignedProjects,
    };
    CompanyAdminServices.createProcurementLogsViews(data)
      .then((res) => {
        setCreateFlag(false);
        setViewName("");
        readCompanySchedule();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const updateProcurementLogsViews = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
      oldViewName: oldViewName,
      viewName: viewName,
      viewDescription: viewDescription,
      columnsInfo: [columnsInfo],
      assignedProjects: assignedProjects,
    };
    CompanyAdminServices.updateProcurementLogsViews(data)
      .then((res) => {
        setCreateFlag(false);
        setEditFlag(false);
        readCompanySchedule();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const toggleAllVisibility = (show) => {
    setColumnsInfo((prev) => {
      const newColumnsInfo = { ...prev };
      Object.keys(newColumnsInfo).forEach((key) => {
        if (
          ![
            "entryID",
            "specSection",
            "specTitle",
            "specNumber",
            "specRevision",
            "specType",
            "submittalPrepTime",
            "submittalIssued",
            "approvalTime",
            "submittalApprovalDue",
            "submittalReturned",
            "leadTime",
            "dateRequiredOnSite",
            "projectedDeliveryDate",
            "custom1",
            "custom2",
            "custom3",
            "custom4",
            "custom5",
            "custom6",
            "custom7",
            "custom8",
            "custom9",
            "custom10",
            "custom11",
            "custom12",
            "custom13",
            "custom14",
            "custom15",
            "custom16",
            "custom17",
            "custom18",
            "custom19",
            "custom20",
            "custom21",
            "custom22",
            "custom23",
            "custom24",
            "custom25",
          ].includes(key)
        ) {
          newColumnsInfo[key] = {
            ...newColumnsInfo[key],
            hidden: show ? "false" : "true",
          };
        }
      });
      return newColumnsInfo;
    });
  };

  const handlePreviewProjectsChange = (value) => {
    getSampleProcurementLogsViews(value);
  };

  let options = Object.entries(projectsDropDown).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const dataSource = dataSample.map((item, index) => ({
    key: index.toString(),
    ...Object.keys(item).reduce((acc, key) => {
      if (columnsInfo[key]?.hidden === "false") {
        acc[key] = item[key];
      }
      return acc;
    }, {}),
  }));

  const columns = Object.keys(columnsInfo)
    .filter((key) => columnsInfo[key].hidden === "false")
    .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
    .map((key) => ({
      title: columnsInfo[key].label,
      dataIndex: key,
      key: key,
      align: "center",
      onCell: () => {
        return {
          style: {
            whiteSpace: "nowrap",
          },
        };
      },
    }));

  useEffect(() => {
    if (options.length > 0 && !initialSetupDone) {
      handlePreviewProjectsChange(options[0].value);
      setInitialSetupDone(true);
    }
  }, [options, initialSetupDone]);

  const viewDetailsColumn = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Column Name",
      dataIndex: "label",
      key: "label",
      align: "left",
      render: (hidden, record) => {
        const keysToShowText = [
          "specTitle",
          "specNumber",
          "specRevision",
          "specResponse",
          "specType",
          "responsibleContractor",
          "issueContract",
          "contractIssuedRelease",
          "submittalRequired",
          "submittalPrepTime",
          "submittalIssued",
          "approvalTime",
          "submittalApprovalDue",
          "submittalReturned",
          "leadTime",
          "scheduleID",
          "dateRequiredOnSite",
          "projectedDeliveryDate",
          "subCommittedDeliveryDate",
          "actualDeliveryDate",
        ];

        return keysToShowText.includes(record.key) ? (
          <span>{record.label}</span>
        ) : (
          <Input
            value={record.label}
            onChange={(e) => handleLabelRename(e.target.value, record.key)}
          />
        );
      },
    },
    // {
    //   title: "Description",
    //   dataIndex: "description",
    //   key: "description",
    //   align: "left",
    //   render: (hidden, record) => {
    //     const keysToShowText = [
    //       "specTitle",
    //       "specNumber",
    //       "specRevision",
    //       "specResponse",
    //       "specType",
    //       "responsibleContractor",
    //       "issueContract",
    //       "contractIssuedRelease",
    //       "submittalRequired",
    //       "submittalPrepTime",
    //       "submittalIssued",
    //       "approvalTime",
    //       "submittalApprovalDue",
    //       "submittalReturned",
    //       "leadTime",
    //       "scheduleID",
    //       "dateRequiredOnSite",
    //       "projectedDeliveryDate",
    //       "subCommittedDeliveryDate",
    //       "actualDeliveryDate",
    //     ];

    //     return keysToShowText.includes(record.key) ? (
    //       <div className=" d-flex align-items-center justify-content-between">
    //         <span>{record.description}</span>
    //         <Tooltip title={record.type}>
    //           <div className=" ms-2 opacity-50">
    //             <InfoCircleFilled />
    //           </div>
    //         </Tooltip>
    //       </div>
    //     ) : (
    //       <div className=" d-flex align-items-center">
    //         <Input
    //           value={record.description}
    //           onChange={(e) =>
    //             handleDescriptionRename(e.target.value, record.key)
    //           }
    //         />
    //         <Tooltip
    //           className=" d-flex ms-2 align-items-center opacity-50"
    //           title={record.type}
    //         >
    //           <div className="">
    //             <InfoCircleFilled />
    //           </div>
    //         </Tooltip>
    //       </div>
    //     );
    //   },
    // },
    {
      title: (
        <div className=" d-flex flex-row justify-content-between">
          <div className="w-100" style={{ marginLeft: "65px" }}>
            Visibility
          </div>
          <div className="w-25">
            <Tooltip title="Toggle All">
              <Switch
                checked={showAllColumns}
                onChange={(checked) => {
                  setShowAllColumns(checked);
                  toggleAllVisibility(checked);
                }}
              />
            </Tooltip>
          </div>
        </div>
      ),
      dataIndex: "Hidden",
      key: "Hidden",
      align: "center",
      render: (hidden, record) => (
        <Switch
          disabled={
            record.key === "specTitle" ||
            record.key === "specNumber" ||
            record.key === "specRevision" ||
            record.key === "specType" ||
            record.key === "submittalPrepTime" ||
            record.key === "submittalIssued" ||
            record.key === "approvalTime" ||
            record.key === "submittalApprovalDue" ||
            record.key === "submittalReturned" ||
            record.key === "leadTime" ||
            record.key === "dateRequiredOnSite" ||
            record.key === "projectedDeliveryDate"
          }
          checked={record.hidden === "false"}
          onChange={() => handleVisibilityToggle(record.key)}
        />
      ),
    },
  ];

  const handleVisibilityToggle = (key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        hidden: prev[key].hidden === "false" ? "true" : "false",
      },
    }));
  };

  const handleLabelRename = (newLabel, key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        label: newLabel,
      },
    }));
  };

  const handleDescriptionRename = (newDescription, key) => {
    setColumnsInfo((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        description: newDescription,
      },
    }));
  };

  const initialModalDataSource = Object.keys(columnsInfo)
    .filter(
      (key) =>
        key !== "entryID" &&
        key !== "specSection" &&
        key !== "specNumber" &&
        key !== "specTitle" &&
        key !== "specRevision" &&
        !key.startsWith("custom")
    )
    .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
    .map((key) => ({
      key,
      ...columnsInfo[key],
    }));

  const [modalDataSource, setModalDataSource] = useState(
    initialModalDataSource
  );

  const handleModalOk = () => {
    setOriginalColumnsInfo(columnsInfo);
    setChangesMadeFlag(true);
    setModalOpen(false);
  };

  const handleModalCancel = () => {
    setColumnsInfo(originalColumnsInfo);
    setModalOpen(false);
  };

  useEffect(() => {
    if (editFlag) {
      const projectIds = selectedViewName.viewProjects.map(
        (project) => project.split("-")[0]
      );
      setAssignedProjects(projectIds);
      setViewsProjects(projectIds);
      setOldViewName(viewName);
    }
  }, [editFlag, selectedViewName]);

  const onDragEnd = ({ active, over }) => {
    // Check if 'over' is null or undefined, indicating a drop outside the table
    if (over) {
      if (active.id !== over.id) {
        setModalDataSource((prev) => {
          const activeIndex = prev.findIndex(
            (record) => record.key === active.id
          );
          const overIndex = prev.findIndex((record) => record.key === over.id);
          const updatedDataSource = arrayMove(prev, activeIndex, overIndex);

          const updatedColumnsInfo = { ...columnsInfo };

          // First, assign fixed indexes
          [
            "entryID",
            "specSection",
            "specTitle",
            "specNumber",
            "specRevision",
          ].forEach((key, idx) => {
            if (updatedColumnsInfo[key]) {
              updatedColumnsInfo[key] = {
                ...updatedColumnsInfo[key],
                index: idx.toString(),
              };
            }
          });

          let newIndex = 5;
          updatedDataSource.forEach((record) => {
            updatedColumnsInfo[record.key] = {
              ...updatedColumnsInfo[record.key],
              index: newIndex.toString(),
            };
            newIndex += 1;
          });

          setColumnsInfo(updatedColumnsInfo);
          return updatedDataSource;
        });
      }
    }
  };

  useEffect(() => {
    const updatedModalDataSource = Object.keys(columnsInfo)
      .filter(
        (key) =>
          key !== "entryID" &&
          key !== "specSection" &&
          key !== "specNumber" &&
          key !== "specRevision" &&
          key !== "specTitle" &&
          !key.startsWith("custom")
      )
      .sort((a, b) => columnsInfo[a].index - columnsInfo[b].index)
      .map((key) => ({
        key,
        ...columnsInfo[key],
      }));

    setModalDataSource(updatedModalDataSource);
  }, [columnsInfo]);

  return (
    <>
      <form
        onSubmit={handleSubmit(
          editFlag ? updateProcurementLogsViews : createProcurementLogsViews
        )}
      >
        <div className="my-4 d-flex justify-content-between">
          <Text>{viewName || "Untitled"}</Text>

          <div className="">
            <Button
              type="ghost"
              onClick={() => {
                setViewName("");
                setViewDescription("");
                setCreateFlag(false);
                setEditFlag(false);
                setChangesMadeFlag(false);
              }}
            >
              Cancel
            </Button>
            {viewName.length < 1 ? (
              <Tooltip title="View Name is a required field">
                <Button type="primary" disabled>
                  Save
                </Button>
              </Tooltip>
            ) : (
              <Button
                type="primary"
                disabled={!isDirty && !changesMadeFlag}
                onClick={() => {
                  editFlag
                    ? updateProcurementLogsViews()
                    : createProcurementLogsViews();
                }}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <div className="my-4 d-flex flex-column justify-content-between">
          <Text className={`${styles.title} mb-2`} dontWrap={false}>
            Information
          </Text>

          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex gap-4">
              <div className="mb-3">
                <label>
                  View Name <span style={{ color: "red" }}>*</span>
                </label>
                <Controller
                  name="viewName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setViewName(e.target.value);
                      }}
                      placeholder="Untitled"
                    />
                  )}
                />
              </div>
              <div className="mb-3">
                <label>View Description</label>
                <Controller
                  name="viewDescription"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        setViewDescription(e.target.value);
                      }}
                      placeholder="Type a description here"
                    />
                  )}
                />
              </div>
            </div>
            <div className="w-25">
              <div className="">
                <label>Assign to Projects</label>
                <Controller
                  name="assignedProjects"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      mode="multiple"
                      allowClear
                      maxTagCount={2}
                      showSearch
                      value={assignedProjects}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      style={{
                        width: "100%",
                      }}
                      placeholder="0 projects selected"
                      onChange={(value) => {
                        field.onChange(value);
                        setAssignedProjects(value);
                      }}
                      options={options}
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <hr className=" w-100" />
          <div className="d-flex flex-column flex-lg-row justify-content-between">
            <div className="d-flex gap-4">
              <div className="mb-3">
                <Text className={`${styles.title} mb-2`} dontWrap={false}>
                  Column Configuration
                </Text>
                <Button
                  type="primary"
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  Configure Columns
                </Button>
              </div>
            </div>
            <div className="w-25">
              <div className="">
                <label>Preview with Project</label>
                <Select
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  style={{
                    width: "100%",
                  }}
                  placeholder="Select a project"
                  onChange={handlePreviewProjectsChange}
                  defaultValue={
                    options.length > 0 ? options[0].value : undefined
                  }
                  options={options}
                />
              </div>
            </div>
          </div>
          <div className=" w-100 m-x-3 overflow-scroll">
            <Table
              style={{
                maxHeight: 500,
              }}
              scroll={{ x: "max-content" }}
              bordered
              dataSource={dataSource}
              columns={columns}
              loading={isLoading ? { indicator: <LoadingComponent /> } : false}
              pagination={false}
            />
          </div>
        </div>

        <Modal
          title="Configure Columns"
          centered
          open={modalOpen}
          onOk={handleModalOk}
          onCancel={handleModalCancel}
          okText="Save"
          width={900}
        >
          <div className=" d-flex">
            <div className=" w-100 ">
              {/* <Table
                style={{
                  maxHeight: 700,
                }}
                scroll={{ y: 550 }}
                bordered
                dataSource={modalDataSource}
                columns={viewDetailsColumn}
                pagination={false}
              /> */}
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={modalDataSource.map((item) => item.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    style={{ maxHeight: 700 }}
                    scroll={{ y: 550 }}
                    bordered
                    dataSource={modalDataSource}
                    columns={viewDetailsColumn}
                    components={{
                      body: {
                        row: SortableRow,
                      },
                    }}
                    pagination={false}
                  />
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </Modal>
      </form>
    </>
  );
}
