import {
  EditRow,
  addNewRow,
  clearDeletedRows,
  clearNewRows,
  deleteRow,
  editNewRows,
  rowsData,
  setUnsavedChanges,
} from "redux/project-reducer";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Select, Tooltip } from "antd";
import ButtonComponent from "components/button-component";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import InputComponent from "components/input-component";
import { Popover } from "antd";
import ProcurementSideMenuContent from "components/Procurement-SideMenu-Content/ProcurementSideMenuContent";
import { ProjectServices } from "apis/Services/ProjectService";
import SelectComponent from "components/select-component";
import { SideBarEndService } from "apis/Services/SideBarService";
import SideMenu from "components/side-menu";
import Text from "components/text";
import commentIcon from "assets/images/sub-division-item-comment-icon.svg";
import lateIcon from "assets/images/late-icon.svg";
import moment, { isDate, weekdays } from "moment";
import pendingIcon from "assets/images/pending-icon.svg";
import { reloadProjects } from "redux/project-reducer";
import styles from "./div.module.css";
import UndoIcon from "@mui/icons-material/Undo";
import { Checkbox } from "antd";
const CheckboxGroup = Checkbox.Group;

const IconShow = ({ icon }) => {
  return <>{icon}</>;
};

const handleFieldIcon = (status) => {
  if (status) {
    if (status == "late") {
      return <img src={lateIcon} alt="late" />;
    } else if (status == "pending") {
      return <img src={pendingIcon} alt="completed" />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
const timeFormat = "MM-DD-YYYY";
const SubDivisionTableItem = ({
  data,
  divName,
  subDivisionName,
  rowindex,
  toggleRowCheck,
  checkedRows,
}) => {
  const dispatch = useDispatch();
  const { company_id, id } = useParams();

  const entryOrigin = data?.entryOrigin;

  const { projectDetails, projectsAccessLevelValue, deletedRows } = useSelector(
    (state) => state.projectDetails
  );
  const { unsavedChanges } = useSelector((state) => state.projectDetails);
  const nonWorkingDays = { ...projectDetails.nonWorkingDays };
  const workingDays = { ...projectDetails.workingDays };
  const IsReadOnly = useMemo(
    () => projectsAccessLevelValue == 1,
    [projectDetails.usersInfo[0]]
  );
  const usersInfo = projectDetails.usersInfo[0];
  const warninyStyleValue = usersInfo?.warningStyle;

  const alignedData = projectDetails.viewsInfo.alignedData;

  const taskIDsAssigned = usersInfo?.taskIDsAssigned?.flat();
  const isItemIDIncluded = taskIDsAssigned.includes(data["entryID"]); // checks if item ID is included in assigned tasks (read_only access only)

  const handleNonWorkingDays = () => {
    let disabledDatesArray = [];

    Object.keys(nonWorkingDays).forEach((key) => {
      const holiday = nonWorkingDays[key];
      var currDate = moment(holiday["startDate"], "MM/DD/YYYY").startOf("day");
      var lastDate = moment(holiday["finishDate"], "MM/DD/YYYY").startOf("day");

      disabledDatesArray.push(moment(currDate).format(timeFormat));
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        disabledDatesArray.push(moment(currDate).format(timeFormat));
      }
      disabledDatesArray.push(moment(lastDate).format(timeFormat));
    });

    let filteredDatesArray = [...new Set(disabledDatesArray)];
    return filteredDatesArray;
  };
  const handleWorkingDays = () => {
    // Convert object keys to an array
    const daysOfWeek = Object.keys(workingDays);

    // Move the first day to the end of the array
    const adjustingDayOffs = daysOfWeek.shift();
    daysOfWeek.push(adjustingDayOffs);

    // Convert the array back to an object
    const adjustedWorkingDays = daysOfWeek.reduce((acc, day, index) => {
      acc[day] = workingDays[day];
      return acc;
    }, {});
    return adjustedWorkingDays;
  };

  const handlePreviousAvailDate = (date) => {
    let weekends = handleWorkingDays();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[new Date(date).getDay()];

    // let weekendIndex = new Date(date).getDay();
    let nonWorkingDaysArr = handleNonWorkingDays();
    let previousDate = moment(date)
      .subtract(1, "days")
      .startOf("day")
      .format(timeFormat);

    if (nonWorkingDaysArr.includes(date) || weekends[dayName] == "0") {
      while (nonWorkingDaysArr.includes(previousDate)) {
        previousDate = moment(previousDate)
          .subtract(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      while (weekends[new Date(previousDate).getDay()] == "0") {
        previousDate = moment(previousDate)
          .subtract(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      return previousDate;
    } else {
      return date;
    }
  };

  const handleNextAvailDate = (date) => {
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[new Date(date).getDay()];
    let weekends = handleWorkingDays();
    // let weekendIndex = new Date(date).getDay();
    let nonWorkingDaysArr = handleNonWorkingDays();
    let nextavailDay = moment(date)
      .add(1, "days")
      .startOf("day")
      .format(timeFormat);
    if (nonWorkingDaysArr.includes(date) || weekends[dayName] == "0") {
      while (nonWorkingDaysArr.includes(nextavailDay)) {
        nextavailDay = moment(nextavailDay)
          .add(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      while (weekends[new Date(nextavailDay).getDay()] == "0") {
        nextavailDay = moment(nextavailDay)
          .add(1, "days")
          .startOf("day")
          .format(timeFormat);
      }
      return nextavailDay;
    } else {
      return date;
    }
  };
  const [SubDivItem, setSubDivItem] = useState({
    id: data["entryID"],
    "Spec Section": data["specSection"],
    Number: data["specNumber"],
    Title: data["specTitle"],
    Revision: data["specRevision"],

    "Submittal Type": data["specType"],

    "Submittal Response": data["specResponse"],

    "Responsible Contractor": data["responsibleContractor"],

    "Issue Contract": moment(data["issueContract"]).isValid()
      ? moment(data["issueContract"]).format("MM-DD-YYYY")
      : "",

    "Contract Issued Release": moment(data["contractIssuedRelease"]).isValid()
      ? moment(data["contractIssuedRelease"]).format("MM-DD-YYYY")
      : "",

    "Submittal Prep Time":
      data["submittalPrepTime"] || projectDetails.reviewTime / 7,
    // ? data[8]
    // : projectDetails.reviewTime / 7,

    // "Submittal Required": moment(data["submittalApprovalDue"])
    //   .subtract(
    //     data["approvalTime"] || projectDetails.approvalTime / 7 || 0,
    //     "weeks"
    //   )
    //   .format(timeFormat),

    "Submittal Required": data["dateRequiredOnSite"]
      ? handlePreviousAvailDate(
          moment(
            handlePreviousAvailDate(
              moment(data["dateRequiredOnSite"])
                .subtract(data["leadTime"] || 0, "weeks")
                .format(timeFormat)
            )
          )
            .subtract(
              data["approvalTime"] || projectDetails.approvalTime / 7 || 0,
              "weeks"
            )
            .format(timeFormat)
        )
      : "",

    "Submittal Issued": moment(data["submittalIssued"]).isValid()
      ? moment(data["submittalIssued"]).format("MM-DD-YYYY")
      : "",

    "Approval Time": data["approvalTime"] || projectDetails.approvalTime / 7,

    // "Submittal Approval Due": data["submittalApprovalDue"],

    "Submittal Approval Due": data["dateRequiredOnSite"]
      ? handlePreviousAvailDate(
          moment(data["dateRequiredOnSite"])
            .subtract(data["leadTime"] || 0, "weeks")
            .format(timeFormat)
        )
      : "",

    "Submittal Returned": moment(data["submittalReturned"]).isValid()
      ? moment(data["submittalReturned"]).format("MM-DD-YYYY")
      : "",

    "Lead Time": data["leadTime"] || 2,

    "Schedule ID": data["scheduleID"],

    "Date Required On Site": moment(data["dateRequiredOnSite"]).isValid()
      ? moment(data["dateRequiredOnSite"]).format("MM-DD-YYYY")
      : "",

    "Projected Delivery Date":
      moment(data["projectedDeliveryDate"]).isValid() &&
      (moment(data["contractIssuedRelease"]).isValid() ||
        moment(data["submittalReturned"]).isValid() ||
        moment(data["submittalIssued"]).isValid())
        ? moment(data["projectedDeliveryDate"]).format("MM-DD-YYYY")
        : "",

    // "Projected Delivery Date": moment(data["projectedDeliveryDate"]).isValid()
    //   ? moment(data["projectedDeliveryDate"]).format("MM-DD-YYYY")
    //   : "",

    "Sub Committed Delivery Date": moment(
      data["subCommittedDeliveryDate"]
    ).isValid()
      ? moment(data["subCommittedDeliveryDate"]).format("MM-DD-YYYY")
      : "",

    "Actual Delivery Date": moment(data["actualDeliveryDate"]).isValid()
      ? moment(data["actualDeliveryDate"]).format("MM-DD-YYYY")
      : "",
    custom1: data["custom1"],
    custom2: data["custom2"],
    custom3: data["custom3"],
    custom4: data["custom4"],
    custom5: data["custom5"],
    custom6: data["custom6"],
    custom7: data["custom7"],
    custom8: data["custom8"],
    custom9: data["custom9"],
    custom10: data["custom10"],
    custom11: data["custom11"],
    custom12: data["custom12"],
    custom13: data["custom13"],
    custom14: data["custom14"],
    custom15: data["custom15"],
    custom16: data["custom16"],
    custom17: data["custom17"],
    custom18: data["custom18"],
    custom19: data["custom19"],
    custom20: data["custom20"],
    custom21: data["custom21"],
    custom22: data["custom22"],
    custom23: data["custom23"],
    custom24: data["custom24"],
    custom25: data["custom25"],
    isEdited: false,
    isNew: data["isNew"] || false,
    div: divName,
    subDiv: subDivisionName,
    index: rowindex,
    isDeleted: data["isDeleted"],
    isHidden: data["isHidden"],
  });

  // const customColumns = [];

  // for (let i = 1; i <= 25; i++) {
  //   const customKey = `custom${i}`;
  //   if (alignedData[customKey]?.hidden === "false") {
  //     customColumns.push(
  //       <td key={customKey}>
  //         <InputComponent
  //           className={`h-100 no-padding-input bg-transparent`}
  //           value={SubDivItem[customKey]} // Using the customKey for the value
  //           customDisabledDates
  //           onChange={(e) => {
  //             handleChange([
  //               {
  //                 name: customKey, // Using the customKey for the name
  //                 value: e,
  //               },
  //               {
  //                 name: "isEdited",
  //                 value: true,
  //               },
  //             ]);
  //           }}
  //         />
  //       </td>
  //     );
  //   }
  // }

  const { newRows, EditedRows } = useSelector((state) => state.projectDetails);
  useEffect(() => {
    if (!data["isHidden"]) {
      let row = newRows.find(
        (x) =>
          x["div"] == divName &&
          x["subDiv"] == subDivisionName &&
          x["index"] == rowindex
      );
      if (row) {
        setSubDivItem({ ...row });
      }
    }
  }, [newRows]);

  useEffect(() => {
    if (!data["isHidden"]) {
      let row = EditedRows.find(
        (x) =>
          x["div"] == divName &&
          x["subDiv"] == subDivisionName &&
          x["index"] == rowindex
      );
      if (row) {
        setSubDivItem({ ...row });
      }
    }
  }, [EditedRows]);

  const handleEditingExceptionsForRealonly = useMemo(() => {
    const hasPermissionToEditLT =
      (usersInfo["leadTimeGranularPermission"] == "3" && isItemIDIncluded) ||
      projectsAccessLevelValue > 1;
    const hasPermissionToEditSubCommited =
      (usersInfo["subCommittedDeliveryDateGranularPermission"] == "3" &&
        isItemIDIncluded) ||
      projectsAccessLevelValue > 1;

    const hasPermissionToViewSubRequired =
      (usersInfo["greyOutSubRequiredDateGranularPermission"] == "0" &&
        isItemIDIncluded) ||
      projectsAccessLevelValue > 1;

    const exceptionsForReadOnly = {
      hasPermissionToEditLT: hasPermissionToEditLT,
      hasPermissionToEditSubCommited: hasPermissionToEditSubCommited,
      hasPermissionToViewSubRequired: hasPermissionToViewSubRequired,
    };
    return exceptionsForReadOnly;
  }, [usersInfo]);

  const handleChange = (objs = []) => {
    let obj = {
      ...SubDivItem,
    };
    objs?.map((x) => {
      if (moment(x.value, timeFormat, true).isValid()) {
        let isProjectedDate = x.name == "Projected Delivery Date";
        if (isProjectedDate) {
          let nextAvailDay = handleNextAvailDate(x.value);
          while (nextAvailDay !== handleNextAvailDate(nextAvailDay)) {
            nextAvailDay = handleNextAvailDate(nextAvailDay);
          }
          obj[x.name] = nextAvailDay;
        } else {
          let previousAvailDay = handlePreviousAvailDate(x.value);
          while (
            previousAvailDay !== handlePreviousAvailDate(previousAvailDay)
          ) {
            previousAvailDay = handlePreviousAvailDate(previousAvailDay);
          }
          obj[x.name] = previousAvailDay;
        }
      } else {
        obj[x.name] = x.value;
      }
    });
    setSubDivItem({
      ...obj,
    });

    if (obj["isNew"] && !obj["isHidden"]) {
      dispatch(editNewRows(obj));
      return;
    } else if (obj["isEdited"]) {
      dispatch(EditRow(obj));
    }
  };
  // set date required on site
  useEffect(() => {
    if (!data["isHidden"]) {
      if (SubDivItem["Schedule ID"]) {
        let id = SubDivItem["Schedule ID"];
        let datesArr = projectDetails.schedule.find((x) => {
          return Object.keys(x)[0] == id;
        });
        let date = datesArr?.[id]?.["startDate"]?.split(" ");

        let selectedDate = date?.length == 2 ? date?.[1] : date?.[0];
        // let FormatedDate = moment(selectedDate).format("YYYY-MM-DD");
        let FormatedDate = moment(selectedDate).format("MM-DD-YYYY");

        let isValidDate = moment(FormatedDate).isValid();
        if (isValidDate) {
          handleChange([
            { name: "Date Required On Site", value: FormatedDate },
          ]);
        }
      }
    }
  }, [SubDivItem["Schedule ID"]]);

  // calculating submittal approval due
  useEffect(() => {
    if (!data["isHidden"]) {
      if (SubDivItem["Date Required On Site"]) {
        handleChange([
          {
            name: "Submittal Approval Due",
            value: moment(SubDivItem["Date Required On Site"])
              .subtract(SubDivItem["Lead Time"] || 0, "weeks")
              .format(timeFormat),
          },
          {
            name: "Submittal Required",
            value: moment(SubDivItem["Date Required On Site"])
              .subtract(SubDivItem["Lead Time"] || 0, "weeks")
              .subtract(
                SubDivItem["Approval Time"] ||
                  projectDetails.approvalTime / 7 ||
                  0,
                "weeks"
              )
              .format(timeFormat),
          },
          {
            name: "Issue Contract",
            value: moment(SubDivItem["Date Required On Site"])
              .subtract(SubDivItem["Lead Time"] || 0, "weeks")
              .subtract(
                SubDivItem["Approval Time"] ||
                  projectDetails.approvalTime / 7 ||
                  0,
                "weeks"
              )
              .subtract(
                SubDivItem["Submittal Prep Time"] ||
                  projectDetails.reviewTime / 7 ||
                  0,
                "weeks"
              )
              .format(timeFormat),
          },
        ]);
      }
    }
  }, [SubDivItem["Date Required On Site"], SubDivItem["Lead Time"]]);

  // submittal approval due vs submittal return
  const [submittalReturnStatus, setsubmittalReturnStatus] = useState("");
  useEffect(() => {
    if (!data["isHidden"]) {
      let approvalDue = SubDivItem["Submittal Approval Due"];
      let approvalReturn = SubDivItem["Submittal Returned"];

      const currentDate = new Date();

      if (!approvalDue) {
        setSubmittalIssuedStatus("");
        setsubmittalReturnStatus("");
        return;
      }

      if (!(approvalDue instanceof Date)) {
        approvalDue = new Date(approvalDue);
      }

      if (approvalReturn) {
        if (!(approvalReturn instanceof Date)) {
          approvalReturn = new Date(approvalReturn);
        }
      }

      let numOfDaysDiff = Math.ceil(
        (approvalDue.getTime() - currentDate.getTime()) / (24 * 60 * 60 * 1000)
      );

      if (!approvalReturn) {
        if (numOfDaysDiff > 7) {
          setsubmittalReturnStatus("");
        } else if (numOfDaysDiff <= 7 && numOfDaysDiff > 0) {
          setsubmittalReturnStatus("pending");
        } else if (numOfDaysDiff <= 0) {
          setsubmittalReturnStatus("late");
        }
      }

      if (approvalReturn) {
        setsubmittalReturnStatus("");
      }
    }
  }, [
    SubDivItem["Submittal Approval Due"],
    SubDivItem["Lead Time"],
    SubDivItem["Schedule ID"],
    SubDivItem["Date Required On Site"],
  ]);

  // calculating submittal required
  useEffect(() => {
    if (!data["isHidden"]) {
      let ApprovalTime = SubDivItem["Approval Time"];
      let submittalApproval = SubDivItem["Submittal Approval Due"];
      if (submittalApproval) {
        let submittalRequired = moment(submittalApproval)
          .subtract(ApprovalTime || 0, "weeks")
          .format(timeFormat);
        handleChange([
          { name: "Submittal Required", value: submittalRequired },
          {
            name: "Issue Contract",
            value: moment(SubDivItem["Date Required On Site"])
              .subtract(SubDivItem["Lead Time"] || 0, "weeks")
              .subtract(
                SubDivItem["Approval Time"] ||
                  projectDetails.approvalTime / 7 ||
                  0,
                "weeks"
              )
              .subtract(
                SubDivItem["Submittal Prep Time"] ||
                  projectDetails.reviewTime / 7 ||
                  0,
                "weeks"
              )
              .format(timeFormat),
          },
        ]);
      }
    }
  }, [SubDivItem["Approval Time"], SubDivItem["Submittal Approval Due"]]);

  // submittal required(ref) vs submittal issued
  const [SubmittalIssuedStatus, setSubmittalIssuedStatus] = useState("");

  useEffect(() => {
    if (!data["isHidden"]) {
      let submittalRequired = SubDivItem["Submittal Required"];
      let submittalIssued = SubDivItem["Submittal Issued"];

      const currentDate = new Date();

      if (!submittalRequired) {
        setSubmittalIssuedStatus("");
        return;
      }

      if (!(submittalRequired instanceof Date)) {
        submittalRequired = new Date(submittalRequired);
      }

      if (submittalIssued) {
        if (!(submittalIssued instanceof Date)) {
          submittalIssued = new Date(submittalIssued);
        }
      }

      let numOfDaysDiff = Math.ceil(
        (submittalRequired.getTime() - currentDate.getTime()) /
          (1000 * 3600 * 24)
      );
      if (!submittalIssued) {
        if (numOfDaysDiff > 7) {
          setSubmittalIssuedStatus("");
        } else if (numOfDaysDiff <= 7 && numOfDaysDiff > 0) {
          setSubmittalIssuedStatus("pending");
        } else if (numOfDaysDiff <= 0) {
          setSubmittalIssuedStatus("late");
        }
      }

      if (submittalIssued) {
        setSubmittalIssuedStatus("");
      }
    }
  }, [SubDivItem["Submittal Required"]]);
  // calculating issue contract
  useEffect(() => {
    if (!data["isHidden"]) {
      let submittalRequired = SubDivItem["Submittal Required"];
      let RT = SubDivItem["Submittal Prep Time"];
      if (submittalRequired) {
        let issueContract = moment(submittalRequired)
          .subtract(RT || 0, "weeks")
          .format(timeFormat);
        handleChange([{ name: "Issue Contract", value: issueContract }]);
      }
    }
  }, [SubDivItem["Submittal Prep Time"], SubDivItem["Submittal Required"]]);

  const location = useLocation();
  const handleDelete = () => {
    let pathsArr = location.pathname.split("/");
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let values = {
      company_id: company_id,
      project_id: id,
      code,
      loginEmail,
      specNumber: SubDivItem["Number"],
      specRevision: SubDivItem["Revision"],
      entryID: SubDivItem["id"],
    };
    handleChange([{ name: "isDeleted", value: true }]);
    dispatch(
      deleteRow({
        values: values,
        index: rowindex,
      })
    );
  };
  const undoDelete = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let values = {
      company_id: company_id,
      project_id: id,
      code,
      loginEmail,
      specNumber: SubDivItem["Number"],
      specRevision: SubDivItem["Revision"],
      entryID: SubDivItem["id"],
    };
    handleChange([{ name: "isDeleted", value: false }]);
    dispatch(
      deleteRow({
        values: values,
        index: rowindex,
      })
    );
  };

  //Calculating Projected Date
  const [projectedDate, setProjectedDate] = useState("");
  const [ProjectedStatus, setProjectedStatus] = useState("");

  /* New projected Date Calculation */
  useEffect(() => {
    if (!data["isHidden"]) {
      const ContractIssuedRelease = moment(
        SubDivItem["Contract Issued Release"]
      );
      const SubmittalIssued = moment(SubDivItem["Submittal Issued"]);
      const SubmittalReturned = moment(SubDivItem["Submittal Returned"]);
      const LT = moment
        .duration(SubDivItem["Lead Time"] || 0, "weeks")
        .asWeeks();
      const dateRequiredOnSite = moment(SubDivItem["Date Required On Site"]);
      const AT = moment
        .duration(SubDivItem["Approval Time"] || 0, "weeks")
        .asWeeks();
      const RT = moment
        .duration(SubDivItem["Submittal Prep Time"] || 0, "weeks")
        .asWeeks();

      let calcDays;
      if (SubmittalReturned.isValid()) {
        calcDays = SubmittalReturned.clone().add(LT, "weeks").toDate();
      } else if (SubmittalIssued.isValid()) {
        calcDays = SubmittalIssued.clone()
          .add(LT, "weeks")
          .add(AT, "weeks")
          .toDate();
      } else if (ContractIssuedRelease.isValid()) {
        calcDays = ContractIssuedRelease.clone()
          .add(LT, "weeks")
          .add(AT, "weeks")
          .add(RT, "weeks")
          .toDate();
      } else {
        calcDays = "";
      }
      const formatProjectedDateFinal = moment(calcDays).format("MM-DD-YYYY");

      setProjectedDate(formatProjectedDateFinal);
      const diffInDays = moment(dateRequiredOnSite._i).diff(
        moment(formatProjectedDateFinal),
        "days"
      );
      if (dateRequiredOnSite) {
        if (diffInDays < 0) {
          setProjectedStatus("late");
        } else {
          setProjectedStatus("");
        }
      }
      if (!dateRequiredOnSite || SubDivItem["Actual Delivery Date"]) {
        setProjectedStatus("");
      }
    }
  }, [
    SubDivItem["Submittal Returned"],
    SubDivItem["Submittal Issued"],
    SubDivItem["Lead Time"],
    SubDivItem["Approval Time"],
    SubDivItem["Submittal Prep Time"],
    SubDivItem["Schedule ID"],
    SubDivItem["Issue Contract"],
    SubDivItem["Contract Issued Release"],
    SubDivItem["Actual Delivery Date"],
  ]);

  const [ContractIssueReleaseStatus, setContractIssueReleaseStatus] = useState(
    <></>
  );

  // Contract Issue Release
  useEffect(() => {
    if (!data["isHidden"]) {
      let contrctIssue = SubDivItem["Issue Contract"];
      let contrctIssueRelease = SubDivItem["Contract Issued Release"];
      const currentDate = new Date();

      if (!contrctIssue) {
        setContractIssueReleaseStatus("");
        return;
      }

      if (!(contrctIssue instanceof Date)) {
        contrctIssue = new Date(contrctIssue);
      }

      if (contrctIssueRelease && !(contrctIssueRelease instanceof Date)) {
        contrctIssueRelease = new Date(contrctIssueRelease);
      }

      let numOfDaysDiff = Math.ceil(
        (contrctIssue.getTime() - currentDate.getTime()) / (1000 * 3600 * 24)
      );

      if (!contrctIssueRelease) {
        if (numOfDaysDiff > 7) {
          setContractIssueReleaseStatus("");
        } else if (numOfDaysDiff > 0 && numOfDaysDiff <= 7) {
          setContractIssueReleaseStatus("pending");
        } else if (numOfDaysDiff <= 0) {
          setContractIssueReleaseStatus("late");
        }
      }
      if (contrctIssueRelease) {
        setContractIssueReleaseStatus("");
      }
    }

    // console.log(numOfDaysDiff, "numOfDaysDiff");
  }, [SubDivItem["Issue Contract"], SubDivItem["Contract Issued Release"]]);

  // Date Required onsite vs Actual date

  const [ActualStatus, setActualStatus] = useState("");

  useEffect(() => {
    if (!data["isHidden"]) {
      let actual = SubDivItem["Actual Delivery Date"];
      let required = SubDivItem["Date Required On Site"];
      const currentDate = new Date();

      if (!required) {
        setActualStatus("");
        return;
      }

      if (!(required instanceof Date)) {
        required = new Date(required);
      }

      if (actual && !(actual instanceof Date)) {
        actual = new Date(actual);
      }

      let timeDifference = required.getTime() - currentDate.getTime();
      let numOfDays = Math.floor(timeDifference / (1000 * 3600 * 24)); // milliseconds to days

      if (!actual) {
        if (numOfDays < 0) {
          setActualStatus("late");
          // console.log("late");
        } else if (numOfDays < 7) {
          setActualStatus("pending");
          // console.log("pending");
        } else {
          setActualStatus("");
          // console.log("fasdfsdfsdfsdf");
        }
      } else {
        setActualStatus("");
      }
    }
  }, [
    SubDivItem["Date Required On Site"],
    SubDivItem["Actual Delivery Date"],
    SubDivItem["Sub Committed Delivery Date"],
  ]);

  const [subCommitedDeliveryStatus, setsubCommitedDeliveryStatus] =
    useState("");
  useEffect(() => {
    if (!data["isHidden"]) {
      let actual = SubDivItem["Actual Delivery Date"];
      let subCommited = SubDivItem["Sub Committed Delivery Date"];
      // let required = SubDivItem["Date Required On Site"];
      const currentDate = new Date();

      if (!subCommited) {
        setsubCommitedDeliveryStatus("");
        return;
      }
      if (!(subCommited instanceof Date)) {
        subCommited = new Date(subCommited);
      }

      let timeDifference = subCommited.getTime() - currentDate.getTime();
      let numOfDays = Math.ceil(timeDifference / (1000 * 3600 * 24)); // milliseconds to days
      if (!actual) {
        if (numOfDays > 7) {
          setsubCommitedDeliveryStatus("");
        } else if (numOfDays <= 7 && numOfDays > 0) {
          setsubCommitedDeliveryStatus("pending");
        } else if (numOfDays <= 0) {
          setsubCommitedDeliveryStatus("late");
        }
      } else {
        setsubCommitedDeliveryStatus("");
      }
    }
  }, [
    // SubDivItem["Actual Delivery Date"],
    SubDivItem["Sub Committed Delivery Date"],
    SubDivItem["Actual Delivery Date"],
  ]);

  // Reset calculations fields when date required on site clears out
  useEffect(() => {
    if (!data["isHidden"]) {
      if (!SubDivItem["Date Required On Site"]) {
        handleChange([
          {
            name: "Submittal Approval Due",
            value: "",
          },
          {
            name: "Submittal Required",
            value: "",
          },
          {
            name: "Issue Contract",
            value: "",
          },
        ]);
      }
    }
  }, [SubDivItem["Date Required On Site"]]);

  // projected delivery date
  useEffect(() => {
    if (!data["isHidden"]) {
      if (moment(projectedDate).isValid()) {
        handleChange([
          {
            name: "Projected Delivery Date",
            value: projectedDate,
          },
        ]);
      }
    }
  }, [projectedDate]);

  const [customRefresh, setcustomRefresh] = useState(false);
  const [scheduleIdError, setscheduleIdError] = useState("");

  useEffect(() => {
    if (!data["isHidden"]) {
      let scheduleIDs = [...projectDetails.schedule];
      if (SubDivItem["Date Required On Site"]) {
        let findElement = scheduleIDs?.find(
          (obj) => SubDivItem["Schedule ID"] in obj
        );

        let formattedElement = moment(
          findElement?.[SubDivItem["Schedule ID"]]["startDate"]
        ).format(timeFormat);

        let formattedDateOnSite = moment(
          [SubDivItem?.["Date Required On Site"]][0]
        ).format(timeFormat);

        let isValidDate = moment(formattedElement).isValid();

        if (findElement && formattedElement !== formattedDateOnSite) {
          setscheduleIdError(
            "The latest uploaded schedule changed this schedule ID’s date"
          );
          handleChange([
            { name: "Date Required On Site", value: formattedElement },
          ]);
        } else if (!findElement && SubDivItem["Schedule ID"]) {
          setscheduleIdError(
            "The latest uploaded schedule no longer has this ID"
          );
        } else if (
          formattedElement == formattedDateOnSite ||
          !SubDivItem["Schedule ID"]
        ) {
          setscheduleIdError("");
        }
        if (!isValidDate) {
          handleChange([{ name: "Date Required On Site", value: "" }]);
        }
      }
    }
  }, [
    // SubDivItem["Date Required On Site"],
    SubDivItem["Schedule ID"],
    customRefresh,
  ]);

  const [isSideMenuVisible, setIsSideMenuVisible] = useState(false);
  // const [scheduleIdIndex, setscheduleIdIndex] = useState(null);
  const handleSideMenu = () => {
    setIsSideMenuVisible(true);
  };

  // handleScheduleId Options
  const sheduleOptions = React.useMemo(() => {
    const tempOptions = [
      ...projectDetails.schedule.map((x) => ({
        label: Object.keys(x)[0] + " - " + Object.values(x)[0]["taskName"],
        value: Object.keys(x)[0],
      })),
    ];
    const options = tempOptions.sort((a, b) => +a.value - +b.value);
    return options;
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onSearch = (value) => {
    // console.log("search:", value);
  };

  const onChange = (value) => {
    // console.log(value, "SELECTED VALUE");
    handleChange([
      { name: "Schedule ID", value: value },
      { name: "isEdited", value: true },
    ]);
    setTimeout(() => {
      setcustomRefresh(!customRefresh);
    }, 100);
  };

  const handleDeletedRowsStyle = useMemo(() => {
    const isDeleted = deletedRows?.some((item) => item["index"] == rowindex);
    return isDeleted;
  }, [deletedRows]);

  const title_tooltip = (
    <>
      <Text fontSize={14}>{SubDivItem["Title"]}</Text>
    </>
  );
  const schedule_tooltip = (
    <>
      <Text fontSize={14}>{scheduleIdError}</Text>
    </>
  );

  const isDisabledRow = useMemo(() => {
    return projectDetails.itemsPendingApprovals?.includes(SubDivItem["id"]);
  }, [projectDetails.itemsPendingApprovals]);

  const excludedKeys = [
    "entryID",
    "specSection",
    "specTitle",
    "specNumber",
    "specRevision",
    "specType",
  ];

  // Convert alignedData object to an array, sort by index, and filter out excluded keys and hidden items
  const sortedColumns = Object.keys(alignedData)
    .filter(
      (key) =>
        !excludedKeys.includes(key) && alignedData[key].hidden === "false"
    )
    .sort((a, b) => alignedData[a].index - alignedData[b].index);

  return (
    <>
      <Tooltip
        title="Item is pending approval"
        overlayStyle={{
          visibility: isDisabledRow ? "visible" : "hidden",
        }}
      >
        <tr
          className={`${styles.tableRow} ${
            handleDeletedRowsStyle ? styles.deletedRow : ""
          } ${isDisabledRow ? styles.disabledRow : null} `}
          style={{ display: SubDivItem.isHidden ? "none" : "table-row" }}
        >
          {/* title */}
          {data["entryID"] ? (
            <td
              // className="d-flex justify-content-center w-100 h-100"
              style={{
                position: "sticky",
                left: -10,
                zIndex: 1,
                backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
                // padding: "30px 20px",
              }}
            >
              <div className="d-flex justify-content-center w-100 h-50">
                <Checkbox
                  className="align-self-center"
                  checked={checkedRows[data["entryID"]]}
                  onChange={() => toggleRowCheck(data["entryID"])}
                  disabled={
                    (!handleEditingExceptionsForRealonly.hasPermissionToEditLT &&
                      !handleEditingExceptionsForRealonly.hasPermissionToEditSubCommited) ||
                    isDisabledRow ||
                    unsavedChanges
                  }
                />
              </div>
            </td>
          ) : (
            <td
              // className="d-flex justify-content-center w-100 h-100"
              style={{
                position: "sticky",
                left: -10,
                zIndex: 1,
                backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
                // padding: "30px 20px",
              }}
            >
              <div className="d-flex justify-content-center w-100 h-100">
                <Checkbox
                  className=" align-self-center"
                  checked={false}
                  disabled
                />
              </div>
            </td>
          )}

          <td
            style={{
              position: "sticky",
              left: 45,
              zIndex: 1,
              backgroundColor: !handleDeletedRowsStyle ? "white" : "grey",
            }}
          >
            <Popover content={title_tooltip}>
              <InputComponent
                // className={`${styles.blueBorder} h-100 no-padding-input bg-transparent`}
                className={` h-100 no-padding-input bg-transparent ${
                  entryOrigin === "Procore"
                    ? styles.orangeBorder
                    : styles.blueBorder
                }`}
                inputIconContainerClass={`flex-row-reverse`}
                hasIcon
                value={SubDivItem["Title"]}
                customDisabledDates
                Icon={
                  <img
                    src={commentIcon}
                    onClick={
                      projectsAccessLevelValue > 1 && !SubDivItem["isNew"]
                        ? handleSideMenu
                        : null
                    }
                    style={{
                      cursor:
                        projectsAccessLevelValue > 1 && !SubDivItem["isNew"]
                          ? "pointer"
                          : "not-allowed",
                    }}
                    alt=""
                  />
                }
                onChange={(e) => {
                  handleChange([
                    {
                      name: "Title",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </Popover>

            {/* <img src={commentIcon} width={20} /> */}
          </td>
          {/* Spec Number */}
          <td>
            <InputComponent
              className={`h-100 no-padding-input bg-transparent`}
              value={SubDivItem["Number"]}
              customDisabledDates
              onChange={(e) => {
                handleChange([
                  {
                    name: "Number",
                    value: e,
                  },
                  {
                    name: "isEdited",
                    value: true,
                  },
                ]);
              }}
            />
          </td>
          {/* Revision */}
          <td>
            <InputComponent
              className={`h-100 no-padding-input bg-transparent`}
              value={SubDivItem["Revision"]}
              customDisabledDates
              onChange={(e) => {
                handleChange([
                  {
                    name: "Revision",
                    value: e,
                  },
                  {
                    name: "isEdited",
                    value: true,
                  },
                ]);
              }}
            />
          </td>
          {/* Submittal Type */}

          <td>
            <InputComponent
              className={`h-100 no-padding-input bg-transparent`}
              value={SubDivItem["Submittal Type"]}
              customDisabledDates
              onChange={(e) => {
                handleChange([
                  {
                    name: "Submittal Type",
                    value: e,
                  },
                  {
                    name: "isEdited",
                    value: true,
                  },
                ]);
              }}
            />
          </td>

          {sortedColumns.map((key) => {
            const dataValue = SubDivItem[key] || "";
            const isHidden = alignedData[key].hidden === "true";

            if (!isHidden) {
              return (
                <td key={key}>
                  {key === "specResponse" && (
                    <div
                      className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                      style={{ color: IsReadOnly ? "grey" : "black" }}
                    >
                      <Text className={`${styles.tableText}`}>
                        {SubDivItem["Submittal Response"] || ""}
                      </Text>
                    </div>
                  )}
                  {key === "responsibleContractor" && (
                    <InputComponent
                      className={`h-100 no-padding-input bg-transparent`}
                      value={SubDivItem["Responsible Contractor"]}
                      customDisabledDates
                      onChange={(e) => {
                        handleChange([
                          {
                            name: "Responsible Contractor",
                            value: e,
                          },
                          {
                            name: "isEdited",
                            value: true,
                          },
                        ]);
                      }}
                    />
                  )}
                  {key === "issueContract" && (
                    <div
                      className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                      style={{ color: IsReadOnly ? "grey" : "black" }}
                    >
                      <Text className={`${styles.tableText}`}>
                        {moment(SubDivItem["Issue Contract"]).isValid()
                          ? moment(SubDivItem["Issue Contract"]).format(
                              "MM-DD-YYYY"
                            )
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "contractIssuedRelease" && (
                    <div>
                      <Text className={`${styles.tableText}`}>
                        <InputComponent
                          className={`h-100 no-padding-input  ${
                            warninyStyleValue === "1" &&
                            ContractIssueReleaseStatus == "late"
                              ? `${styles.lateColored}`
                              : warninyStyleValue === "1" &&
                                ContractIssueReleaseStatus == "pending"
                              ? `${styles.pendingColored}`
                              : `${styles.ff}`
                          }`}
                          value={SubDivItem["Contract Issued Release"]}
                          Icon={handleFieldIcon(ContractIssueReleaseStatus)}
                          type={
                            SubDivItem["Contract Issued Release"] ? "date" : ""
                          }
                          isDate={true}
                          disabled={IsReadOnly || isDisabledRow}
                          customDisabledDates
                          disabledDate={true}
                          onChange={(e) => {
                            const isContractIssuedReleaseValid =
                              moment(e).isValid();
                            const isSubmittalIssuedValid = moment(
                              SubDivItem["Submittal Issued"]
                            ).isValid();
                            const isSubmittalReturnedValid = moment(
                              SubDivItem["Submittal Returned"]
                            ).isValid();

                            const shouldEmptyProjectedDeliveryDate =
                              !isContractIssuedReleaseValid &&
                              !isSubmittalIssuedValid &&
                              !isSubmittalReturnedValid;

                            handleChange([
                              {
                                name: "Contract Issued Release",
                                value: e,
                              },
                              {
                                name: "isEdited",
                                value: true,
                              },
                              {
                                name: "Projected Delivery Date",
                                value: shouldEmptyProjectedDeliveryDate
                                  ? ""
                                  : SubDivItem["Projected Delivery Date"],
                              },
                            ]);
                          }}
                        />
                      </Text>
                    </div>
                  )}
                  {key === "submittalPrepTime" && (
                    <div className={`${styles.smallCells}`}>
                      <InputComponent
                        className={`h-100 no-padding-input p-0 `}
                        inputClassName="text-center"
                        value={SubDivItem["Submittal Prep Time"]}
                        customDisabledDates
                        onChange={(e) => {
                          handleChange([
                            {
                              name: "Submittal Prep Time",
                              value: e,
                            },
                            {
                              name: "isEdited",
                              value: true,
                            },
                          ]);
                        }}
                      />
                    </div>
                  )}
                  {key === "submittalRequired" && (
                    <div
                      className={`${styles.Tabledata} w-100`}
                      style={{
                        justifyContent: moment(
                          SubDivItem["Submittal Required"]
                        ).isValid()
                          ? "center"
                          : "flex-start",
                        color: IsReadOnly ? "grey" : "black",
                      }}
                    >
                      <Text className={`${styles.tableText}`}>
                        {moment(SubDivItem["Submittal Required"]).isValid() &&
                        handleEditingExceptionsForRealonly.hasPermissionToViewSubRequired
                          ? moment(SubDivItem["Submittal Required"]).format(
                              "MM-DD-YYYY"
                            )
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "submittalIssued" && (
                    <div
                      className={`${styles.Tabledata}
                    d-flex align-items-center gap-2  ${
                      warninyStyleValue === "1" &&
                      SubmittalIssuedStatus == "late"
                        ? `${styles.lateColored}`
                        : warninyStyleValue === "1" &&
                          SubmittalIssuedStatus == "pending"
                        ? `${styles.pendingColored}`
                        : `${styles.ff}`
                    }`}
                      style={{
                        justifyContent: moment(
                          SubDivItem["Submittal Issued"]
                        ).isValid()
                          ? "center"
                          : "flex-start",
                        color: IsReadOnly || isDisabledRow ? "grey" : "black",
                      }}
                    >
                      <IconShow icon={handleFieldIcon(SubmittalIssuedStatus)} />
                      <Text className={`${styles.tableText}`}>
                        {moment(SubDivItem["Submittal Issued"]).isValid()
                          ? moment(SubDivItem["Submittal Issued"]).format(
                              "MM-DD-YYYY"
                            )
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "approvalTime" && (
                    <div className={`${styles.smallCells}`}>
                      <Text className={`${styles.tableText}`}>
                        <InputComponent
                          className={`h-100 no-padding-input p-0`}
                          inputClassName="text-center"
                          value={SubDivItem["Approval Time"]}
                          customDisabledDates
                          onChange={(e) => {
                            handleChange([
                              { name: "Approval Time", value: e },

                              {
                                name: "isEdited",
                                value: true,
                              },
                            ]);
                          }}
                        />
                      </Text>
                    </div>
                  )}
                  {key === "submittalApprovalDue" && (
                    <div
                      className={`${styles.Tabledata} w-100`}
                      style={{ color: IsReadOnly ? "grey" : "black" }}
                    >
                      <Text className={`${styles.tableText}`}>
                        {moment(SubDivItem["Submittal Approval Due"]).isValid()
                          ? moment(SubDivItem["Submittal Approval Due"]).format(
                              "MM-DD-YYYY"
                            )
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "submittalReturned" && (
                    <div
                      className={`${styles.Tabledata} w-100 
                    ${
                      warninyStyleValue === "1" &&
                      submittalReturnStatus == "late"
                        ? `${styles.lateColored}`
                        : warninyStyleValue === "1" &&
                          submittalReturnStatus == "pending"
                        ? `${styles.pendingColored}`
                        : `${styles.ff}`
                    }
                    `}
                      style={{
                        justifyContent: moment(
                          SubDivItem["Submittal Returned"]
                        ).isValid()
                          ? "center"
                          : "flex-start",
                      }}
                    >
                      <Text
                        className={`${styles.tableText} d-flex align-items-center gap-2`}
                      >
                        <IconShow
                          icon={handleFieldIcon(submittalReturnStatus)}
                        />
                        {moment(SubDivItem["Submittal Returned"]).isValid()
                          ? moment(SubDivItem["Submittal Returned"]).format(
                              "MM-DD-YYYY"
                            )
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "leadTime" && (
                    <div
                      className={`${styles.smallCells} 
                    ${
                      !handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                      isDisabledRow
                        ? styles.disabledRow
                        : styles.exception
                    }
                      `}
                    >
                      <InputComponent
                        className={`h-100 no-padding-input p-0 `}
                        inputClassName="text-center"
                        value={SubDivItem["Lead Time"] || ""}
                        customDisabledDates
                        disabled={
                          !handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                          isDisabledRow
                        }
                        onChange={(e) => {
                          handleChange([
                            { name: "Lead Time", value: e },

                            {
                              name: "isEdited",
                              value: true,
                            },
                          ]);
                        }}
                      />
                    </div>
                  )}
                  {key === "scheduleID" && (
                    <>
                      {scheduleIdError ? (
                        <Popover content={schedule_tooltip}>
                          <Select
                            className={`${styles.sheduleSelect} ${
                              (IsReadOnly || isDisabledRow) &&
                              styles.customDisabled
                            }`}
                            showSearch
                            bordered={false}
                            size="large"
                            placement="bottomLeft"
                            placeholder={SubDivItem["Schedule ID"]}
                            optionFilterProp="children"
                            onChange={onChange}
                            allowClear={
                              SubDivItem["Schedule ID"] &&
                              SubDivItem["Schedule ID"].length > 1
                            }
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={sheduleOptions}
                            value={SubDivItem["Schedule ID"]}
                            dropdownMatchSelectWidth={false}
                            dropdownStyle={{ width: "200px" }}
                          />
                        </Popover>
                      ) : (
                        <Select
                          className={`${styles.sheduleSelect} ${
                            (IsReadOnly || isDisabledRow) &&
                            styles.customDisabled
                          }`}
                          showSearch
                          bordered={false}
                          size="large"
                          placement="bottomLeft"
                          placeholder={SubDivItem["Schedule ID"]}
                          optionFilterProp="children"
                          onChange={onChange}
                          allowClear={
                            SubDivItem["Schedule ID"] &&
                            SubDivItem["Schedule ID"].length > 1
                          }
                          onSearch={onSearch}
                          filterOption={filterOption}
                          options={sheduleOptions}
                          value={SubDivItem["Schedule ID"]}
                          dropdownMatchSelectWidth={false}
                          dropdownStyle={{ width: "200px" }}
                        />
                      )}
                    </>
                  )}
                  {key === "dateRequiredOnSite" && (
                    <InputComponent
                      className={`h-100 no-padding-input`}
                      // type="date"
                      type={SubDivItem["Date Required On Site"] ? "date" : ""}
                      value={SubDivItem["Date Required On Site"]}
                      isDate={true}
                      disabled={IsReadOnly || isDisabledRow}
                      customDisabledDates
                      onChange={(e) => {
                        let Changes = [
                          { name: "Date Required On Site", value: e },
                          { name: "Schedule ID", value: "" },
                          {
                            name: "isEdited",
                            value: true,
                          },
                        ];

                        handleChange(Changes);
                        setcustomRefresh(!customRefresh);
                      }}
                    />
                  )}
                  {key === "projectedDeliveryDate" && (
                    <div
                      className={`${styles.Tabledata} w-100 gap-2
                    ${
                      warninyStyleValue === "1" && ProjectedStatus == "late"
                        ? `${styles.lateColored}`
                        : warninyStyleValue === "1" &&
                          ProjectedStatus == "pending"
                        ? `${styles.pendingColored}`
                        : `${styles.ff}`
                    }
                    `}
                      style={{
                        justifyContent: ProjectedStatus
                          ? "flex-start"
                          : "center",
                        color: IsReadOnly || isDisabledRow ? "grey" : "black",
                      }}
                    >
                      <IconShow icon={handleFieldIcon(ProjectedStatus)} />
                      <Text className={`${styles.tableText} `}>
                        {SubDivItem["Projected Delivery Date"] !==
                        "Invalid date"
                          ? SubDivItem["Projected Delivery Date"]
                          : ""}
                      </Text>
                    </div>
                  )}
                  {key === "subCommittedDeliveryDate" && (
                    <InputComponent
                      className={`h-100 no-padding-input ${
                        handleEditingExceptionsForRealonly.hasPermissionToEditLT &&
                        isDisabledRow
                          ? styles.disabledRow
                          : styles.exception
                      }
                   ${
                     warninyStyleValue === "1" &&
                     subCommitedDeliveryStatus == "late"
                       ? `${styles.lateColored}`
                       : warninyStyleValue === "1" &&
                         subCommitedDeliveryStatus == "pending"
                       ? `${styles.pendingColored}`
                       : `${styles.ff}`
                   }
                   `}
                      Icon={handleFieldIcon(subCommitedDeliveryStatus)}
                      type={
                        SubDivItem["Sub Committed Delivery Date"]
                          ? "date"
                          : "text"
                      }
                      value={SubDivItem["Sub Committed Delivery Date"]}
                      isDate={true}
                      disabled={
                        !handleEditingExceptionsForRealonly.hasPermissionToEditSubCommited ||
                        isDisabledRow
                      }
                      customDisabledDates
                      onChange={(e) =>
                        handleChange([
                          { name: "Sub Committed Delivery Date", value: e },
                          {
                            name: "isEdited",
                            value: true,
                          },
                        ])
                      }
                    />
                  )}
                  {key === "actualDeliveryDate" && (
                    <div>
                      <InputComponent
                        className={`h-100 no-padding-input
                                  ${
                                    warninyStyleValue === "1" &&
                                    ActualStatus == "late"
                                      ? `${styles.lateColored}`
                                      : warninyStyleValue === "1" &&
                                        ActualStatus == "pending"
                                      ? `${styles.pendingColored}`
                                      : `${styles.ff}`
                                  }
                                  `}
                        Icon={handleFieldIcon(ActualStatus)}
                        type={
                          SubDivItem["Actual Delivery Date"] ? "date" : "text"
                        }
                        value={SubDivItem["Actual Delivery Date"]}
                        isDate={true}
                        disabled={IsReadOnly || isDisabledRow}
                        customDisabledDates
                        disabledDate={true}
                        onChange={(e) =>
                          handleChange([
                            { name: "Actual Delivery Date", value: e },
                            {
                              name: "isEdited",
                              value: true,
                            },
                          ])
                        }
                      />
                    </div>
                  )}
                  {key.includes("custom") &&
                    (alignedData[key].type === "string" ? (
                      <InputComponent
                        className={`h-100 no-padding-input bg-transparent`}
                        value={dataValue}
                        customDisabledDates
                        onChange={(e) => {
                          handleChange([
                            {
                              name: key,
                              value: e,
                            },
                            {
                              name: "isEdited",
                              value: true,
                            },
                          ]);
                        }}
                      />
                    ) : alignedData[key].type === "date" ? (
                      <InputComponent
                        className={`h-100 no-padding-input`}
                        type={"date"}
                        value={SubDivItem[key]}
                        isDate={true}
                        disabled={IsReadOnly || isDisabledRow}
                        customDisabledDates
                        onChange={(e) => {
                          let Changes = [
                            { name: key, value: e },
                            {
                              name: "isEdited",
                              value: true,
                            },
                          ];

                          handleChange(Changes);
                          setcustomRefresh(!customRefresh);
                        }}
                      />
                    ) : null)}
                </td>
              );
            } else {
              return null; // if hidden
            }
          })}

          {/* Submittal Response */}

          {/* {alignedData["specResponse"].hidden === "false" && (
            <td>
              <div
                className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                style={{ color: IsReadOnly ? "grey" : "black" }}
              >
                <Text className={`${styles.tableText}`}>
                  {SubDivItem["Submittal Response"] || ""}
                </Text>
              </div>
            </td>
          )} */}

          {/* Responsible Contractor */}

          {/* {alignedData["responsibleContractor"].hidden === "false" && (
            <td>
              <InputComponent
                className={`h-100 no-padding-input bg-transparent`}
                value={SubDivItem["Responsible Contractor"]}
                customDisabledDates
                onChange={(e) => {
                  handleChange([
                    {
                      name: "Responsible Contractor",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </td>
          )} */}

          {/* Issue Contract */}

          {/* {alignedData["issueContract"].hidden === "false" && (
            <td>
              <div
                className={`${styles.Tabledata} d-flex align-items-center gap-2`}
                style={{ color: IsReadOnly ? "grey" : "black" }}
              >
                <Text className={`${styles.tableText}`}>
                  {moment(SubDivItem["Issue Contract"]).isValid()
                    ? moment(SubDivItem["Issue Contract"]).format("MM-DD-YYYY")
                    : ""}
                </Text>
              </div>
            </td>
          )} */}

          {/* Contract Issued Release*/}
          {/* {alignedData["contractIssuedRelease"].hidden === "false" && (
            <td>
              <div>
                <Text className={`${styles.tableText}`}>
                  <InputComponent
                    className={`h-100 no-padding-input  ${
                      warninyStyleValue === "1" &&
                      ContractIssueReleaseStatus == "late"
                        ? `${styles.lateColored}`
                        : warninyStyleValue === "1" &&
                          ContractIssueReleaseStatus == "pending"
                        ? `${styles.pendingColored}`
                        : `${styles.ff}`
                    }`}
                    value={SubDivItem["Contract Issued Release"]}
                    Icon={handleFieldIcon(ContractIssueReleaseStatus)}
                    type={SubDivItem["Contract Issued Release"] ? "date" : ""}
                    isDate={true}
                    disabled={IsReadOnly || isDisabledRow}
                    customDisabledDates
                    disabledDate={true}
                    onChange={(e) => {
                      const isContractIssuedReleaseValid = moment(e).isValid();
                      const isSubmittalIssuedValid = moment(
                        SubDivItem["Submittal Issued"]
                      ).isValid();
                      const isSubmittalReturnedValid = moment(
                        SubDivItem["Submittal Returned"]
                      ).isValid();

                      const shouldEmptyProjectedDeliveryDate =
                        !isContractIssuedReleaseValid &&
                        !isSubmittalIssuedValid &&
                        !isSubmittalReturnedValid;

                      handleChange([
                        {
                          name: "Contract Issued Release",
                          value: e,
                        },
                        {
                          name: "isEdited",
                          value: true,
                        },
                        {
                          name: "Projected Delivery Date",
                          value: shouldEmptyProjectedDeliveryDate
                            ? ""
                            : SubDivItem["Projected Delivery Date"],
                        },
                      ]);
                    }}
                  />
                </Text>
              </div>
            </td>
          )} */}
          {/* review time RT  ====  submittal prep time */}

          {/* <td>
            <div className={`${styles.smallCells}`}>
              <InputComponent
                className={`h-100 no-padding-input p-0 `}
                inputClassName="text-center"
                value={SubDivItem["Submittal Prep Time"]}
                customDisabledDates
                onChange={(e) => {
                  handleChange([
                    {
                      name: "Submittal Prep Time",
                      value: e,
                    },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </div>
          </td> */}

          {/* Submittal Required get it using calculation */}
          {/* {alignedData["submittalRequired"].hidden === "false" && (
            <td>
              <div
                className={`${styles.Tabledata} w-100`}
                style={{
                  justifyContent: moment(
                    SubDivItem["Submittal Required"]
                  ).isValid()
                    ? "center"
                    : "flex-start",
                  color: IsReadOnly ? "grey" : "black",
                }}
              >
                <Text className={`${styles.tableText}`}>
                  {moment(SubDivItem["Submittal Required"]).isValid() &&
                  handleEditingExceptionsForRealonly.hasPermissionToViewSubRequired
                    ? moment(SubDivItem["Submittal Required"]).format(
                        "MM-DD-YYYY"
                      )
                    : ""}
                </Text>
              </div>
            </td>
          )} */}
          {/* submittal issued */}

          {/* <td>
            <div
              className={`${styles.Tabledata}
             d-flex align-items-center gap-2  ${
               warninyStyleValue === "1" && SubmittalIssuedStatus == "late"
                 ? `${styles.lateColored}`
                 : warninyStyleValue === "1" &&
                   SubmittalIssuedStatus == "pending"
                 ? `${styles.pendingColored}`
                 : `${styles.ff}`
             }`}
              style={{
                justifyContent: moment(SubDivItem["Submittal Issued"]).isValid()
                  ? "center"
                  : "flex-start",
                color: IsReadOnly || isDisabledRow ? "grey" : "black",
              }}
            >
              <IconShow icon={handleFieldIcon(SubmittalIssuedStatus)} />
              <Text className={`${styles.tableText}`}>
                {moment(SubDivItem["Submittal Issued"]).isValid()
                  ? moment(SubDivItem["Submittal Issued"]).format("MM-DD-YYYY")
                  : ""}
              </Text>
            </div>
          </td> */}

          {/* Approval time AT */}
          {/* <td>
            <div className={`${styles.smallCells}`}>
              <Text className={`${styles.tableText}`}>
                <InputComponent
                  className={`h-100 no-padding-input p-0`}
                  inputClassName="text-center"
                  value={SubDivItem["Approval Time"]}
                  customDisabledDates
                  onChange={(e) => {
                    handleChange([
                      { name: "Approval Time", value: e },

                      {
                        name: "isEdited",
                        value: true,
                      },
                    ]);
                  }}
                />
              </Text>
            </div>
          </td> */}

          {/* submittal approval due  calculation */}
          {/* <td>
            <div
              className={`${styles.Tabledata} w-100`}
              style={{ color: IsReadOnly ? "grey" : "black" }}
            >
              <Text className={`${styles.tableText}`}>
                {moment(SubDivItem["Submittal Approval Due"]).isValid()
                  ? moment(SubDivItem["Submittal Approval Due"]).format(
                      "MM-DD-YYYY"
                    )
                  : ""}
              </Text>
            </div>
          </td> */}

          {/* Submittal Returned */}
          {/* <td>
            <div
              className={`${styles.Tabledata} w-100 
              ${
                warninyStyleValue === "1" && submittalReturnStatus == "late"
                  ? `${styles.lateColored}`
                  : warninyStyleValue === "1" &&
                    submittalReturnStatus == "pending"
                  ? `${styles.pendingColored}`
                  : `${styles.ff}`
              }
              `}
              style={{
                justifyContent: moment(
                  SubDivItem["Submittal Returned"]
                ).isValid()
                  ? "center"
                  : "flex-start",
              }}
            >
              <Text
                className={`${styles.tableText} d-flex align-items-center gap-2`}
              >
                <IconShow icon={handleFieldIcon(submittalReturnStatus)} />
                {moment(SubDivItem["Submittal Returned"]).isValid()
                  ? moment(SubDivItem["Submittal Returned"]).format(
                      "MM-DD-YYYY"
                    )
                  : ""}
              </Text>
            </div>
          </td> */}

          {/* Lead time LT */}
          {/* <td>
            <div
              className={`${styles.smallCells} 
              ${
                !handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                isDisabledRow
                  ? styles.disabledRow
                  : styles.exception
              }
                `}
            >
              <InputComponent
                className={`h-100 no-padding-input p-0 `}
                inputClassName="text-center"
                value={SubDivItem["Lead Time"] || ""}
                customDisabledDates
                disabled={
                  !handleEditingExceptionsForRealonly.hasPermissionToEditLT ||
                  isDisabledRow
                }
                onChange={(e) => {
                  handleChange([
                    { name: "Lead Time", value: e },

                    {
                      name: "isEdited",
                      value: true,
                    },
                  ]);
                }}
              />
            </div>
          </td> */}

          {/* {alignedData["scheduleID"].hidden === "false" && (
            <td>
              {scheduleIdError ? (
                <Popover content={schedule_tooltip}>
                  <Select
                    className={`${styles.sheduleSelect} ${
                      (IsReadOnly || isDisabledRow) && styles.customDisabled
                    }`}
                    showSearch
                    bordered={false}
                    size="large"
                    placement="bottomLeft"
                    placeholder={SubDivItem["Schedule ID"]}
                    optionFilterProp="children"
                    onChange={onChange}
                    allowClear={
                      SubDivItem["Schedule ID"] &&
                      SubDivItem["Schedule ID"].length > 1
                    }
                    onSearch={onSearch}
                    filterOption={filterOption}
                    options={sheduleOptions}
                    value={SubDivItem["Schedule ID"]}
                    dropdownMatchSelectWidth={false}
                    dropdownStyle={{ width: "200px" }}
                  />
                </Popover>
              ) : (
                <Select
                  className={`${styles.sheduleSelect} ${
                    (IsReadOnly || isDisabledRow) && styles.customDisabled
                  }`}
                  showSearch
                  bordered={false}
                  size="large"
                  placement="bottomLeft"
                  placeholder={SubDivItem["Schedule ID"]}
                  optionFilterProp="children"
                  onChange={onChange}
                  allowClear={
                    SubDivItem["Schedule ID"] &&
                    SubDivItem["Schedule ID"].length > 1
                  }
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={sheduleOptions}
                  value={SubDivItem["Schedule ID"]}
                  dropdownMatchSelectWidth={false}
                  dropdownStyle={{ width: "200px" }}
                />
              )}
            </td>
          )} */}
          {/* //////////////////////////////////////////////////////////////////////// */}
          {/* date Required onsite  */}
          {/* <td>
            <InputComponent
              className={`h-100 no-padding-input`}
              // type="date"
              type={SubDivItem["Date Required On Site"] ? "date" : ""}
              value={SubDivItem["Date Required On Site"]}
              isDate={true}
              disabled={IsReadOnly || isDisabledRow}
              customDisabledDates
              onChange={(e) => {
                let Changes = [
                  { name: "Date Required On Site", value: e },
                  { name: "Schedule ID", value: "" },
                  {
                    name: "isEdited",
                    value: true,
                  },
                ];

                handleChange(Changes);
                setcustomRefresh(!customRefresh);
              }}
            />
          </td> */}
          {/* 
          <td>
            <div
              className={`${styles.Tabledata} w-100 gap-2
              ${
                warninyStyleValue === "1" && ProjectedStatus == "late"
                  ? `${styles.lateColored}`
                  : warninyStyleValue === "1" && ProjectedStatus == "pending"
                  ? `${styles.pendingColored}`
                  : `${styles.ff}`
              }
              `}
              style={{
                justifyContent: ProjectedStatus ? "flex-start" : "center",
                color: IsReadOnly || isDisabledRow ? "grey" : "black",
              }}
            >
              <IconShow icon={handleFieldIcon(ProjectedStatus)} />
              <Text className={`${styles.tableText} `}>
                {SubDivItem["Projected Delivery Date"] !== "Invalid date"
                  ? SubDivItem["Projected Delivery Date"]
                  : ""}
              </Text>
            </div>
          </td> */}

          {/* Sub Committed Delivery Date */}

          {/* {alignedData["subCommittedDeliveryDate"].hidden === "false" && (
            <td>
              <InputComponent
                className={`h-100 no-padding-input ${
                  handleEditingExceptionsForRealonly.hasPermissionToEditLT &&
                  isDisabledRow
                    ? styles.disabledRow
                    : styles.exception
                }
              ${
                warninyStyleValue === "1" && subCommitedDeliveryStatus == "late"
                  ? `${styles.lateColored}`
                  : warninyStyleValue === "1" &&
                    subCommitedDeliveryStatus == "pending"
                  ? `${styles.pendingColored}`
                  : `${styles.ff}`
              }
              `}
                Icon={handleFieldIcon(subCommitedDeliveryStatus)}
                type={
                  SubDivItem["Sub Committed Delivery Date"] ? "date" : "text"
                }
                value={SubDivItem["Sub Committed Delivery Date"]}
                isDate={true}
                disabled={
                  !handleEditingExceptionsForRealonly.hasPermissionToEditSubCommited ||
                  isDisabledRow
                }
                customDisabledDates
                onChange={(e) =>
                  handleChange([
                    { name: "Sub Committed Delivery Date", value: e },
                    {
                      name: "isEdited",
                      value: true,
                    },
                  ])
                }
              />
            </td>
          )} */}

          {/* user input or */}

          {/* {alignedData["actualDeliveryDate"].hidden === "false" && (
            <td>
              <div>
                <InputComponent
                  className={`h-100 no-padding-input
                ${
                  warninyStyleValue === "1" && ActualStatus == "late"
                    ? `${styles.lateColored}`
                    : warninyStyleValue === "1" && ActualStatus == "pending"
                    ? `${styles.pendingColored}`
                    : `${styles.ff}`
                }
                `}
                  Icon={handleFieldIcon(ActualStatus)}
                  type={SubDivItem["Actual Delivery Date"] ? "date" : "text"}
                  value={SubDivItem["Actual Delivery Date"]}
                  isDate={true}
                  disabled={IsReadOnly || isDisabledRow}
                  customDisabledDates
                  disabledDate={true}
                  onChange={(e) =>
                    handleChange([
                      { name: "Actual Delivery Date", value: e },
                      {
                        name: "isEdited",
                        value: true,
                      },
                    ])
                  }
                />
              </div>
            </td>
          )} */}

          <td className={`${styles.actions} text-center`}>
            {!SubDivItem.Number &&
              (handleDeletedRowsStyle ? (
                <span
                  className="d-flex justify-content-center"
                  style={{ color: "blue" }}
                  onClick={() => undoDelete()}
                >
                  <UndoIcon className="text-center pointer" />
                </span>
              ) : (
                <span className="d-flex justify-content-center">
                  <DeleteOutlineOutlinedIcon
                    className={`align-content-center pointer ${
                      projectsAccessLevelValue < 3 ||
                      handleDeletedRowsStyle ||
                      isDisabledRow
                        ? styles.disabledDeleteButton
                        : styles.deleteButton
                    }`}
                    onClick={
                      projectsAccessLevelValue < 3 || isDisabledRow
                        ? null
                        : handleDelete
                    }
                  />
                </span>
              ))}
          </td>
        </tr>
      </Tooltip>
      {isSideMenuVisible && (
        <SideMenu
          show={isSideMenuVisible}
          setShow={setIsSideMenuVisible}
          isRight={true}
          title={SubDivItem["div"]}
          isSmall={false}
          subTitle={SubDivItem["Spec Section"]}
          taskId={SubDivItem["id"]}
        >
          <ProcurementSideMenuContent
            accessLevel={projectsAccessLevelValue}
            content={SubDivItem}
          />
        </SideMenu>
      )}
    </>
  );
};
export default SubDivisionTableItem;
