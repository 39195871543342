import {
  Button,
  Popover,
  Select,
  Space,
  Spin,
  Table,
  Modal,
  Input,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Container from "components/container";
import ContentContainer from "components/content-container";
import { DeleteOutlined } from "@material-ui/icons";
import InfoIcon from "@mui/icons-material/Info";
import GrowingAlert from "components/growing-alert";
import { PermissionServices } from "apis/Services/PermissionsService";
import Permissions from "pages/permissions";
import UseGetCompanyPermissions from "hooks/request-hooks/use-get-company-permissions";
import UseGetProjectPermissions from "hooks/request-hooks/use-get-project-permissions";
import tableData from "../../components/permission-table/tableData";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "components/loading-component";
import { saveUserAccessLevel } from "redux/permission-reducer";
import Text from "components/text";

const UserPermissionTable = ({
  type = "user_permission",
  showPermissionsTable,
}) => {
  const tablesSelectedData = {
    user_permission: {
      column: tableData("user_permission_columns"),
      data: tableData("user_permission_data"),
    },
  };
  const permissionsCollection = {
    column: tableData("permissions_collection_column"),
    data: tableData("permissions_collection_data"),
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.user === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const { companyPermissions, projectPermissions, userAccessLevel } =
    useSelector((state) => state.permission);
  const { company_id } = useParams();
  const [CompanyContacts, setCompanyContacts] = useState({});
  const [templateNameFilter, setTemplateNameFilter] = useState("");
  const [tableLoader, settableLoader] = useState(true);
  const dispatch = useDispatch();
  const project_permissions = UseGetProjectPermissions({ company_id });
  const company_permissions = UseGetCompanyPermissions({ company_id });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({});

  //Get Company and Project Permissions
  useEffect(() => {
    console.log(project_permissions, "project_permissions");
    console.log(company_permissions, "company_permissions");
  }, []);

  const handleCompanyPermissionChange = (value, record) => {
    let UpdatedRecord =
      record === "Apply Permission Template Later"
        ? (record = "Default")
        : record;
    console.log(record, "Company record");

    editUser(value, UpdatedRecord, "company");
  };
  const handleProjectPermissionChange = (value, record) => {
    let UpdatedRecord =
      record === "Apply Permission Template Later"
        ? (record = "Default")
        : record;
    console.log(record, "project record");

    editUser(value, UpdatedRecord, "project");
  };

  const handleDeleteCompanyContacts = (record) => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    let data = {
      company_id,
      loginEmail,
      code,
      inviteeEmail: record.EmailAddress,
    };

    PermissionServices.removeCompanyContactsTemplate(data)
      .then((res) => {
        console.log(res);
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {});
  };

  const readCompanyContacts = () => {
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");
    let data = {
      company_id,
      loginEmail,
      code,
    };
    PermissionServices.readCompanyContactsTemplate(data)
      .then((res) => {
        console.log(res, "res");
        dispatch(saveUserAccessLevel(res?.accessLevel));
        setCompanyContacts(res?.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((res) => {
        setTimeout(() => {
          settableLoader(false);
        });
      });
  };
  // ////////////////////////////

  const editUser = (value, record, type) => {
    // console.log(record, "record");
    let code = localStorage.getItem("token");
    let loginEmail = localStorage.getItem("email");

    // Check if record.ProjectPermissions needs to be changed
    let inviteeProjectPermission =
      type === "project"
        ? value
        : record.ProjectPermissions == "Apply Permission Template Later"
        ? "Default"
        : "";

    // Check if record.CompanyPermissions needs to be changed
    let inviteeCompanyPermission =
      type === "company"
        ? value
        : record.CompanyPermissions == "Apply Permission Template Later"
        ? "Default"
        : "";

    if (inviteeProjectPermission == "Apply Permission Template Later") {
      inviteeProjectPermission = "Default";
    }
    if (inviteeCompanyPermission == "Apply Permission Template Later") {
      inviteeCompanyPermission = "Default";
    }
    let data = {
      code,
      inviteeEmail: record.EmailAddress,
      loginEmail,
      inviteeProjectPermission,
      inviteeCompanyPermission,
    };

    PermissionServices.editUserPermission(data, company_id)
      .then((res) => {
        console.log(res, "res");
        setTimeout(() => {
          window.location.reload();
        }, 600);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const multiple_tooltip = (
    <>
      <Text fontSize={14}>{"This user has multiple permission levels"}</Text>
    </>
  );

  const showModal = (record) => {
    setUserInfo(record);
    setIsModalOpen(true);
  };

  const TableData = {
    BasicData: Object.keys(CompanyContacts)
      .map((key, index) => {
        const keyValue = CompanyContacts[key];
        return keyValue.map((info, index2) => ({
          key: Math.floor(Math.random() * 10000).toString(),
          status: info.Status,
          Company: info.Company,
          Phone: info.Phone,
          ProjectPermissions:
            info["Company Permissions"] == "Default"
              ? "Apply Permission Template Later"
              : info["Company Permissions"],
          User: info["First Name"] + " " + info["Last Name"],
          CompanyPermissions:
            info["Company Permissions"] == "Default"
              ? "Apply Permission Template Later"
              : info["Company Permissions"],
          CompanyAddress: info["Company Address"],
          EmailAddress: info["Email Address"],
        }));
      })
      .flat(),
    BasicColumns: [
      {
        render: (_, record) => (
          <Space size="small">
            {userAccessLevel == 3 ? (
              <Button
                type="link"
                onClick={() => {
                  handleDeleteCompanyContacts(record);
                }}
                icon={<DeleteOutlined style={{ color: "red" }} />}
              />
            ) : null}
          </Space>
        ),
        width: 50,
      },

      {
        title: "Company",
        dataIndex: "Company",
      },

      {
        title: "User",
        dataIndex: "User",
        render: (text, record) => (
          <>
            <span
              style={{ cursor: "pointer", color: "#0496DB" }}
              onClick={() => {
                showModal(record);
              }}
            >
              {text}
            </span>
          </>
        ),
      },
      {
        title: "Company Permissions",
        dataIndex: "CompanyPermissions",
        render: (_, record) => {
          // console.log(record.EmailAddress, "record");
          const Data = Object.keys(CompanyContacts)
            .map((key, index) => {
              const keyValue = CompanyContacts[key];
              return keyValue.map((info, index2) => info);
            })
            .flat();

          const emailToFind = record.EmailAddress;

          const CompanyTest = Data.find(
            (contact) => contact["Email Address"] === emailToFind
          );

          if (CompanyTest) {
            var CompanyplaceholderValue = CompanyTest["Company Permissions"];
            // console.log("Company Permissions:", CompanyplaceholderValue);
          } else {
            // console.log("Contact not found.");
          }

          record.CompanyPermissions = CompanyplaceholderValue;
          if (
            CompanyTest[
              "differentCompanyPermissionsAcrossDifferentProjectsFlag"
            ] === "1"
          ) {
            CompanyplaceholderValue = "Multiple Permission Levels Detected";
          } else if (CompanyplaceholderValue === "Default") {
            CompanyplaceholderValue = "Apply Permission Template Later";
          }

          return CompanyTest[
            "differentCompanyPermissionsAcrossDifferentProjectsFlag"
          ] === "1" ? (
            <Popover content={multiple_tooltip}>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder={CompanyplaceholderValue}
                onChange={(e) => handleCompanyPermissionChange(e, record)}
                disabled={
                  userAccessLevel == 1 ||
                  CompanyplaceholderValue == "Account Owner"
                }
                options={companyPermissions?.content?.map((e) => ({
                  label:
                    e?.["Template Name"] === "Default"
                      ? "Apply Permission Template Later"
                      : e?.["Template Name"],
                  value:
                    e?.["Template Name"] === "Default"
                      ? "Apply Permission Template Later"
                      : e?.["Template Name"],
                }))}
              />
            </Popover>
          ) : (
            <Select
              style={{
                width: "100%",
              }}
              placeholder={CompanyplaceholderValue}
              onChange={(e) => handleCompanyPermissionChange(e, record)}
              disabled={
                userAccessLevel == 1 ||
                CompanyplaceholderValue == "Account Owner"
              }
              options={companyPermissions?.content?.map((e) => ({
                label:
                  e?.["Template Name"] === "Default"
                    ? "Apply Permission Template Later"
                    : e?.["Template Name"],
                value:
                  e?.["Template Name"] === "Default"
                    ? "Apply Permission Template Later"
                    : e?.["Template Name"],
              }))}
            />
          );
        },
      },
      {
        title: "Project Permissions",
        dataIndex: "ProjectPermissions",
        render: (_, record) => {
          const Data = Object.keys(CompanyContacts)
            .map((key, index) => {
              const keyValue = CompanyContacts[key];
              return keyValue.map((info, index2) => info);
            })
            .flat();

          const emailToFind = record.EmailAddress;

          const CompanyTest = Data.find(
            (contact) => contact["Email Address"] === emailToFind
          );

          if (CompanyTest) {
            var ProjectplaceholderValue = CompanyTest["Project Permissions"];
            // console.log("project Permissions:", ProjectplaceholderValue);
          } else {
            // console.log("Contact not found.");
          }
          record.ProjectPermissions = ProjectplaceholderValue;
          if (
            CompanyTest[
              "differentProjectPermissionsAcrossDifferentProjectsFlag"
            ] === "1"
          ) {
            ProjectplaceholderValue = "Multiple Permission Levels Detected";
          } else if (ProjectplaceholderValue === "Default") {
            ProjectplaceholderValue = "Apply Permission Template Later";
          }

          return CompanyTest[
            "differentProjectPermissionsAcrossDifferentProjectsFlag"
          ] === "1" ? (
            <Popover content={multiple_tooltip}>
              <Select
                style={{
                  width: "100%",
                }}
                placeholder={ProjectplaceholderValue}
                onChange={(e) => handleProjectPermissionChange(e, record)}
                disabled={
                  userAccessLevel == 1 ||
                  ProjectplaceholderValue == "Account Owner"
                }
                options={projectPermissions?.content?.map((e) => ({
                  label:
                    e?.["Template Name"] === "Default"
                      ? "Apply Permission Template Later"
                      : e?.["Template Name"],
                  value:
                    e?.["Template Name"] === "Default"
                      ? "Apply Permission Template Later"
                      : e?.["Template Name"],
                }))}
              />
            </Popover>
          ) : (
            <Select
              style={{
                width: "100%",
              }}
              placeholder={ProjectplaceholderValue}
              onChange={(e) => handleProjectPermissionChange(e, record)}
              disabled={
                userAccessLevel == 1 ||
                ProjectplaceholderValue == "Account Owner"
              }
              options={projectPermissions?.content?.map((e) => ({
                label:
                  e?.["Template Name"] === "Default"
                    ? "Apply Permission Template Later"
                    : e?.["Template Name"],
                value:
                  e?.["Template Name"] === "Default"
                    ? "Apply Permission Template Later"
                    : e?.["Template Name"],
              }))}
            />
          );
        },
      },
      // {
      //   title: "Permission",
      //   dataIndex: "permission",
      //   render: (_, record) => {
      //     return <Select
      //     // mode=""
      //     // allowClear
      //     style={{
      //       width: "100%",
      //     }}
      //     placeholder="Actions"
      //     onChange={handleChange}
      //     options={menuOptions}
      //   />
      //   },
      // },
      {
        title: "Status",
        dataIndex: "status",
        render: (_, record) => {
          return (
            <GrowingAlert
              type={
                record?.status == "Not Invited" ? "pending" : record?.status
              }
              text={record?.status}
            />
          );
        },
      },
    ],
  };

  const loader = {
    spinning: tableLoader,
    indicator: <LoadingComponent />,
  };

  useEffect(() => {
    readCompanyContacts();
  }, []);

  let filteredData = [...TableData?.BasicData].filter(
    (item) =>
      item.Company.toLowerCase().includes(templateNameFilter.toLowerCase()) ||
      item.User.toLowerCase().includes(templateNameFilter.toLowerCase()) ||
      item.status.toLowerCase().includes(templateNameFilter.toLowerCase()) ||
      item.CompanyPermissions.toLowerCase().includes(
        templateNameFilter.toLowerCase()
      ) ||
      item.ProjectPermissions.toLowerCase().includes(
        templateNameFilter.toLowerCase()
      )
  );
  return (
    <>
      <Modal
        title="User Information"
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <div className="mb-3 mt-3">
          <label>Full Name:</label>
          <Input value={userInfo?.User} style={{ pointerEvents: "none" }} />
        </div>
        <div className="mb-3">
          <label>Email Address:</label>
          <Input
            value={userInfo?.EmailAddress}
            style={{ pointerEvents: "none" }}
          />
        </div>
        <div className="mb-3">
          <label>Phone Number:</label>
          <Input value={userInfo["Phone"]} style={{ pointerEvents: "none" }} />
        </div>
        <div className="mb-3">
          <label>Company:</label>
          <Input value={userInfo?.Company} style={{ pointerEvents: "none" }} />
        </div>
        <div className="mb-3">
          <label>Company Address:</label>
          <Input
            value={userInfo["CompanyAddress"]}
            style={{ pointerEvents: "none" }}
          />
        </div>
        <div className="mb-3">
          <label>Company Permissions:</label>
          <Input
            value={
              userInfo["CompanyPermissions"] === "Default"
                ? "Apply Permission Template Later"
                : userInfo["CompanyPermissions"]
            }
            style={{ pointerEvents: "none" }}
          />
        </div>
        <div className="mb-3">
          <label>Project Permissions:</label>
          <Input
            value={
              userInfo["ProjectPermissions"] === "Default"
                ? "Apply Permission Template Later"
                : userInfo["ProjectPermissions"]
            }
            // value={userInfo["ProjectPermissions"]}
            style={{ pointerEvents: "none" }}
          />
        </div>
      </Modal>

      <Permissions
        pageType="user_permission"
        templateNameFilter={templateNameFilter}
        setTemplateNameFilter={setTemplateNameFilter}
        newPermissionTemplateNameToEdit
        userAccessLevel={userAccessLevel}
      />
      {userAccessLevel == 0 ? (
        <div className="mt-5 text-center">
          <Text>
            You do not have the necessary permissions to access this page.
          </Text>
        </div>
      ) : (
        <Container>
          <ContentContainer className="P-3">
            <div className="my-2">
              {!showPermissionsTable ? (
                <Table
                  // rowSelection={type == "user_permission" ? rowSelection : false}
                  // columns={tablesSelectedData[type].column}
                  // dataSource={tablesSelectedData[type].data}
                  columns={TableData.BasicColumns}
                  dataSource={[...filteredData]}
                  loading={loader}
                  // dataSource={TableData.BasicData}
                  bordered
                  pagination={{
                    pageSize: 10,
                    // current: currentPage,
                    // showSizeChanger: true,
                    // position: ["bottomLeft"],
                  }}
                  scroll={{
                    x: "max-content",
                  }}
                />
              ) : (
                <Table
                  columns={permissionsCollection.column}
                  dataSource={permissionsCollection.data}
                  loading={tableLoader}
                  pagination={{
                    pageSize: 10,
                    // current: currentPage,
                    // showSizeChanger: true,
                    // position: ["bottomLeft"],
                  }}
                  scroll={{
                    x: "max-content",
                  }}
                />
              )}
            </div>
          </ContentContainer>
        </Container>
      )}
    </>
  );
};

export default UserPermissionTable;
